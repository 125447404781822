import React, {useEffect, useState} from 'react';
import Select from 'react-select'
import cn from "classnames";
import {useFormikContext} from "formik";

const FormSelect = ({name, onChange, defaultValue, options, title, classWrap='float__field', children}) => {
    const {values, setFieldValue} = useFormikContext()
    const [toggle, setToggle] = useState(false)
    const [value, setValue] = useState()
    if (values[name] && values[name] !== value?.value && options?.length){
        options.map(item => item.value === values[name] && setValue(item))
    }

    const colourStyles = {
        singleValue: styles => ({
           ...styles,
           fontSize: '14px'
        }),
        valueContainer: styles => ({
            ...styles,
            padding: '15px 10px 10px',
            height: '50px',
        }),
        indicatorsContainer: style => ({
           ...style,
           display: 'none',
        }),
        control: style => ({
            ...style,
            borderColor: '#E1E1E1',
            background: 'url("/img/deliveryarrow.svg")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto',
            backgroundPosition: '95% center',
            boxShadow: 'none',
            ':hover': {
                borderColor: '#E1E1E1',
                cursor: 'pointer'
            }
        }),
        menu: style => ({
            ...style,
            zIndex: '1111',
        }),
        menuList: style => ({
            ...style,
            maxHeight: '205px',
            overflowY: 'auto'
        }),
        option: styles => ({
            ...styles,
            fontWeight: '500',
        })
    };
    const onChangeSelect = (e) =>{
        setToggle(true);
        setFieldValue(name, e.value);
        onChange && onChange(e);
    }
    const addDefaultValue = () => {
        if (defaultValue && !values[name]) {
            onChange && onChange(defaultValue)
        }
    }
    useEffect(addDefaultValue, [defaultValue])
    return (
        <div className={classWrap}>
            <span className={cn({'active': toggle || value})}>{title}</span>
            <Select value={value} onChange={onChangeSelect} isSearchable={false} styles={colourStyles} options={options} placeholder='' />
            {values[name] === 'add' ? children : null}
        </div>
    )
}
export default FormSelect