import React, { Fragment, useState } from "react";
import { Button, Modal, Accordion, Card } from "react-bootstrap";
import cn from "classnames";
import "../style.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
const ModalBudgetAllocate = ({ budget, myProducts, onHide, show, currency, onConfirm, tax_info }) => {
  console.log(myProducts);
  const [reviewToggle, setReviewToggle] = useState(false);
  const [summaryToggle, setSummaryToggle] = useState(false);
  let totalAmount = 0;
  myProducts.map(item => totalAmount += +item.total_price_cents);
  const extraAmount = Math.abs((budget - totalAmount));
  const extraAmountToBePaid = Math.abs((budget - totalAmount)) + (tax_info?.tax_amount || 0);
  return (
    <>
      <Modal className="allocate-budget" show={show} onHide={onHide} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            Confirm and Enjoy Your Meal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <Accordion defaultActiveKey="0">
            <Card className="border-bottom bg-white mb-3 mt-3">
              <Card.Header className="bg-white">
                <Accordion.Toggle
                  className="w-100 text-dark p-0 "
                  as={Button}
                  variant="link"
                  eventKey="0"
                  onClick={() => setReviewToggle(prevState => !prevState)}
                >
                  <div className="mb-2 d-flex text-decoration-none align-items-center justify-content-between">
                    <h6 className="m-0">Order Review</h6>
                    <span className={cn('open-close-arrow', { 'focused__dropdown': reviewToggle })}>
                      <img src="/img/dropdownarrow.svg" alt="dropdownarrow" />
                    </span>
                  </div>
                  <div className="d-flex text-decoration-none align-items-center justify-content-between">
                    <small className="m-0 font-light">{myProducts?.length || 0} Items in cart</small>
                    <small>{currency + (+totalAmount / 100).toFixed(2)}</small>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="border-none" eventKey="0">
                <Card.Body className="border-0">
                  {myProducts?.map((product) => {
                    let portions = 0;
                    product?.menu_items?.length ? Object.values(product.menu_items_mapping).map(item => (portions += +item?.quantity)) : (portions = product?.quantity);
                    return (
                      (
                        <Fragment key={product?.id}>
                          <div className="order-list d-flex justify-content-between align-items-center">
                            <span className="order-name">{product?.name}</span>
                            <hr />
                            <span className="order-price">{currency + (+product.total_price_cents / 100).toFixed(2)}  (QTY: {portions || 0})</span>
                          </div>
                          {product?.menu_items?.filter(menuItem => {
                              const { quantity } = product?.menu_items_mapping[menuItem?.id] || {};
                              return quantity > 0; // Filter out items with quantity 0 or less
                            })
                            ?.map((menuItem) => {
                              const { price, quantity } = product?.menu_items_mapping[menuItem?.id] || {}
                              return (
                                <div className="order-list-menu-items d-flex justify-content-between align-items-center">
                                  <span className="order-name">{menuItem?.name}</span>
                                  <hr />
                                  <span className="order-price">{currency + (+price / 100).toFixed(2)}  (QTY: {quantity || 0})</span>
                                </div>
                              )
                            })}
                        </Fragment>
                      )
                    )
                  })}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="border-bottom  bg-white mb-3">
              <Card.Header className="bg-white ">
                <Accordion.Toggle
                  className="w-100 text-dark p-0"
                  as={Button}
                  variant="link"
                  eventKey="1"
                  onClick={() => setSummaryToggle(prevState => !prevState)}
                >
                  <div className="mb-2 d-flex text-decoration-none align-items-center justify-content-between">
                    <h6 className="m-0">Check out summary</h6>
                    <span className={cn('open-close-arrow', { 'focused__dropdown': summaryToggle })}>
                      <img src="/img/dropdownarrow.svg" alt="dropdownarrow" />
                    </span>
                  </div>
                  {tax_info &&
                    <div className="mb-2 d-flex text-decoration-none align-items-center justify-content-between">
                      <small className="m-0 font-light">{`${tax_info?.tax_name} (${(tax_info?.tax_rate * 100).toFixed(2)}%)`}</small>
                      <small>{currency + (+tax_info?.tax_amount / 100).toFixed(2)}</small>
                    </div>
                  }
                  <div className="mb-2 d-flex text-decoration-none align-items-center justify-content-between">
                    <small className="m-0 font-light">Alloted Budget</small>
                    <small>{currency + (+budget / 100).toFixed(2)}</small>
                  </div>
                  <div className="d-flex text-decoration-none align-items-center justify-content-between">
                    <small className="m-0 font-light">
                      Extra Amount
                    </small>
                    <small>{currency + (+extraAmount / 100).toFixed(2)}</small>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse className="border-none" eventKey="1">
                <Card.Body className="border-0">
                  <div className="d-flex text-decoration-none align-items-center justify-content-between">
                    <h6 className="m-0">Total</h6>
                    <span>{currency + (+extraAmountToBePaid / 100).toFixed(2)}</span>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <div className="act-btn d-flex mb-3">
            <Button className="btn-primary flex-fill" variant="primary" onClick={onHide}>
              Adjust Order
            </Button>
            <Button onClick={onConfirm} className='flex-fill' variant="primary">Pay Extra {currency + (+extraAmountToBePaid / 100).toFixed(2)}</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalBudgetAllocate;
