import Modal from "react-bootstrap/Modal";
import React from "react";
import {CANCEL_GROUP_ORDER} from "../../../../store/Common/Common.constant";
import {useDispatch} from "react-redux";

const ModalCancelGroupOrder = ({id, onHide, show}) => {
    const dispatch = useDispatch();
    const handlerReplaced = (method) => {
        switch (method) {
            case 'TRUE': {
                dispatch({type: CANCEL_GROUP_ORDER, payload: id});
                return onHide()
            }
            case 'FALSE': {
                return onHide()
            }
            default: return
        }
    }
    return (
        <Modal show={show} size="lg" centered>
            <Modal.Body>
                <div className="modal__wrapper">
                    <div className="inner__modal">
                        <div className="replace__modal">
                            <div className="replace__icon">
                                <img src="/img/modalwarn.svg" alt="modalwarn" />
                            </div>
                            <h6>Are you sure you want to cancel the entire order? If so all existing team member orders will be removed/voided. Proceed?</h6>
                            <div className="replace__buttons">
                                <button onClick={() => handlerReplaced('TRUE')} type='button' className="replace__confirm">Yes</button>
                                <button onClick={() => handlerReplaced('FALSE')} type='button' className="close__replace">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ModalCancelGroupOrder