import React, {useEffect} from "react";
import {useFormikContext} from "formik";
import cn from "classnames";
import {useState} from "react";
import FormInput from "../index";

const FormInputSelect = ({minDate, data, select, name, classContainer, ...props}) => {
    const {values, setFieldValue} = useFormikContext();
    const handlerChangeValue = () => {
        if (data.indexOf(values[name]) === -1){
            setFieldValue(name, data[0])
        }
    }
    useEffect(handlerChangeValue,[values])
    const [indexActive, setIndexActive] = useState(0)
    const [toggle, setToggle] = useState(false)
    let sortArrData = [...data];
    let path = name.replace(/\[(\w+)\]/g, '.$1').split('.');
    let val = values;
    path.map(item => val[item] ? (val = val[item]) : (val = ''))
    if(data){
        sortArrData = data.filter(post => {
            if (!select && !val) return null;
            return post.toLowerCase().slice(0, val.length).includes(val.toLowerCase())
        })
        if (select) {
            (val && !sortArrData.includes(val)) && sortArrData.unshift(val);
            data.map(item => {
                return !sortArrData.includes(item) ? sortArrData.push(item) : null;
            })
        }
    }
    const btnUpDown = (keyCode, e) => {
        if(keyCode === 40 && indexActive < sortArrData.length - 1){
            setIndexActive(indexActive + 1)
        }
        if(keyCode === 38 && indexActive > 0){
            setIndexActive(indexActive - 1)
        }
        if(keyCode === 13){
            e.preventDefault()
            setFieldValue(name, sortArrData[indexActive])
            setIndexActive(0)
        }
    }
    return (
        <div className={cn(classContainer, "FormInputSelect")}>
            <FormInput withoutContainer={true} name={name} {...props} options={{
                autoComplete: "off",
                onKeyDown: e => btnUpDown(e.keyCode, e),
                onClick: () => setToggle(true)
            }} />
            {((sortArrData.length && !select) || (select && toggle)) ? <ul className="FormInputSelect__ul">
                {sortArrData.map((item, index) => <li className={cn({'active': index === indexActive, 'val': val === item})} key={index} onClick={() => setFieldValue(name, item)}>{item}</li>)}
            </ul> : null}
        </div>
    )
}
export default FormInputSelect