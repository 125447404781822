import React from "react";

export const FormInputOutput = ({className, children, ...props}) => {

    if (props.withoutContainer) return children

    return (!className) ?
        <div className="form-item-full">{children}</div> :
        <div className={className}>{children}</div>
}

export default FormInputOutput
