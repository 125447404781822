export const MODAL_TOGGLE_PRODUCT = '@modal/MODAL_TOGGLE_PRODUCT';
export const MODAL_TOGGLE = '@modal/MODAL_TOGGLE';
export const CLOSE_MODAL_AUTH = '@modal/CLOSE_MODAL_AUTH';
export const ADD_MODAL_PRODUCT = '@modal/ADD_MODAL_PRODUCT';
export const CHANGE_MODAL_PRODUCT = '@modal/CHANGE_MODAL_PRODUCT';
export const MODAL_CHANGE_ORDER = '@modal/MODAL_CHANGE_ORDER';
export const NEW_USER_MODAL = '@modal/NEW_USER_MODAL';
export const MODAL_CLEAR_OLD_GO = '@modal/MODAL_CLEAR_OLD_GO';
export const OPEN_MODAL_BUDGET_ALLOCATED = '@modal/OPEN_MODAL_BUDGET_ALLOCATED';
export const CLOSE_MODAL_BUDGET_ALLOCATED = '@modal/CLOSE_MODAL_BUDGET_ALLOCATED';
export const OPEN_MODAL_STRIPE = '@modal/OPEN_MODAL_STRIPE';
export const CLOSE_MODAL_STRIPE = '@modal/CLOSE_MODAL_STRIPE';
