import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import cn from "classnames";

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: '' };
    }

    handleChange = address => {
        this.props.setFieldValue(this.props.name, '');
        this.setState({ address });
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.change = false;
                this.props.setFieldValue(this.props.name, address)
                this.props.setFieldValue('delivery_latitude', latLng.lat)
                this.props.setFieldValue('delivery_longitude', latLng.lng)
                this.setState({ address: address });
            })
            .catch(error => console.error('Error', error));
    };
    handleBlur = (e) => {
        e.forEach(item => {
            if (item.description === this.state.address) {
                this.handleSelect(this.state.address);
            }
        })
    };
    componentDidMount() {
        if (this.props.value) {
            this.setState({ address: this.props.value });
        }
    }

    render() {
        return (
            <PlacesAutocomplete
                searchOptions={{
                    types: ['address']
                }}
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <>
                        <input
                            {...getInputProps({
                                placeholder: 'Enter Street Address for Delivery ...',
                                className: 'location-search-input',
                                disabled: this.props.disabled
                            })}
                            className={cn("no__span", {error: this.props.error})}
                            onBlur={() => this.handleBlur(suggestions)}
                        />
                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        key={index}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </PlacesAutocomplete>
        );
    }
}
export default LocationSearchInput