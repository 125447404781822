import React from "react";

const ImagePreview = ({image, lazy = true, alt, ...props}) => {
    return (
        <>
            {image
                ? typeof(image) === "object"
                    ? image.retina
                        ? <img alt={alt} {...props} loading={lazy ? "lazy" : 'eager'} src={image.base} srcSet={`${image.base} 1x, ${image.retina} 2x`} />
                        : <img alt={alt} {...props} loading="lazy" src={image.base} />
                    : <img alt={alt} {...props} loading="lazy" src={image} />
                : null
            }
        </>
    )
}
export default ImagePreview