import React from "react";
import {useTab} from "../index";

const TabLink = ({id, children, link = false, ...props}) => {

    const [state, dispatch] = useTab()

    const handleChange = (e => {
        e.preventDefault()
        dispatch(id)
    })

    function wrapLink(item) {
        return React.cloneElement(item, {
            onClick: (e) => handleChange(e)
        })
    }

    return wrapLink(
        state.layout.link(children, state.current === id, (e) => handleChange(e))
    )
}

export default TabLink