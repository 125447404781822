import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const StripeElementForm = ({ onHide, exceededAmount, orderHandler }) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        try {
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement('card'),
            });
            orderHandler(result.paymentMethod.id);
        } catch (err) {
        }
        onHide();
    };

    return (

        <form className='border rounded stripe-modal' onSubmit={handleSubmit}>
            <div className='p-4'>
                <CardElement options={{ hidePostalCode: true }} />
            </div>
            <div className='px-4 pb-4'>
                <button className="btn btn-block btn-primary" type="submit" disabled={!stripe}>
                    Pay ${(+exceededAmount / 100).toFixed(2)}
                </button>
            </div>
            <p className='px-4 text-danger text-center'>Your amount is not refundable</p>
        </form>
    );
};

export default StripeElementForm;