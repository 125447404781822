import React, {useState} from "react";
import {useFormikContext} from "formik";
import cn from "classnames";

const UploadFile = ({setErrorCSV, name, accept}) => {
    const {values, setFieldValue} = useFormikContext();
    const [toggle, setToggle] = useState(false);
    return (
        <>
            {!values[name]
                ? <div className={cn('upload__contacts', {'active__drag': toggle})}>
                    <input onDragEnter={() => setToggle(true)} onDragLeave={() => setToggle(false)}
                           id={name} name={name} type="file" accept={accept} onChange={(e) => {
                            let acceptArr = '.doc,.csv'.split('.').map(i => i.replace(',', ''))
                            let fileFormat = e.currentTarget.files[0].name.split('.')[1]
                            let permission = false;
                            setErrorCSV(false)
                            acceptArr.forEach(i => i === fileFormat && (permission = true));
                            permission && setFieldValue(name, e.currentTarget.files[0]);
                            !permission && setErrorCSV(true);
                        }}
                    />
                    <label htmlFor={name}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="39.642" height="27.999"
                                 viewBox="0 0 39.642 27.999">
                                <path id="Fill_1" data-name="Fill 1"
                                      d="M31.806,28H9.22A9.138,9.138,0,0,1,0,18.967a8.9,8.9,0,0,1,2.423-6.1A9.256,9.256,0,0,1,8.369,9.978a11.08,11.08,0,0,1,3.3-6.675,11.7,11.7,0,0,1,16.293,0,11.107,11.107,0,0,1,3.3,9.343h.547a7.871,7.871,0,0,1,5.546,2.244,7.592,7.592,0,0,1,0,10.867A7.872,7.872,0,0,1,31.806,28ZM18.372,12.033V22.067a.938.938,0,0,0,1.876,0V12.033l3.591,3.253a.917.917,0,0,0,.619.226,1.016,1.016,0,0,0,.715-.284.845.845,0,0,0,.238-.63.983.983,0,0,0-.311-.7L19.94,9.233A.818.818,0,0,0,19.324,9H19.31a1.033,1.033,0,0,0-.631.233L13.521,13.9a.908.908,0,0,0-.3.641.945.945,0,0,0,1.565.745l3.589-3.251Z"
                                      transform="translate(0 -0.001)" fill="#e02020"/>
                            </svg>
                        </span> Upload CSV file
                    </label>
                </div>
                : <div className="imported__info downloaded__file">
                    <div className="template__download">
                        <div className="template__info">
                            <img src="img/templateicon.svg" alt="templateicon" />
                            <p>{values[name].name}</p>
                        </div>
                        <div className="template__download--button">
                            <p>Imported <img src="img/checksuccess.svg" alt="checksuccess" /></p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default UploadFile;