import React from 'react';
import './style.scss'

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="outer__footer">
                    <div className="top__footer">
                        <ul>
                            <li><a href="https://cateredclub.com/">About</a></li>
                            <li><a href="https://cateredclub.com/">Learn More</a></li>
                            <li><a href="https://cateredclub.com/">Legal </a></li>
                            <li><a href="https://cateredclub.com/">Partners</a></li>
                            <li><a href="https://cateredclub.com/">Get Started</a></li>
                        </ul>
                    </div>
                    <div className="bottom__footer">
                        <div className="bottom__left">
                            <a href="https://cateredclub.com/">
                                <img src="/img/footlogo.svg" alt="footlogo" />
                            </a>
                        </div>
                        <div className="bottom__center">
                            <p>© 2021 CateredClub. All rights reserved.</p>
                        </div>
                        <div className="bottom__right">
                            <ul>
                                <li><a href="https://cateredclub.com/"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://cateredclub.com/"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="https://cateredclub.com/"><i className="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer
