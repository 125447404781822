import React, {useEffect, useRef, useState} from "react";
import cn from "classnames";
import {useFormikContext} from "formik";
import ModalCloseSettingsDelivery from "../Modals/ModalCloseSettingsDelivery";

const Accordion = ({disabled, modal, title, defaultToggle = false, children, modalTitle, name}) => {
    const [toggle, setToggle] = useState(defaultToggle);
    const [toggleModal, setToggleModal] = useState(false);
    const {values, setFieldValue} = useFormikContext();
    const ref = useRef();
    const [scrollHeight, setScrollHeight] = useState(ref?.current?.scrollHeight);

    useEffect(() => {
        defaultToggle && setToggle(defaultToggle);
    }, [defaultToggle])
    const handlerComponent = () => {
        if (ref?.current?.scrollHeight !== scrollHeight) {
            setScrollHeight(ref?.current?.scrollHeight);
        }
        if (name && values[name] !== toggle) {
            setFieldValue(name, toggle);
        }
    };
    useEffect(handlerComponent);

    const onClickToggle = () => {
        if (!disabled) {
            modal && toggle
                ? setToggleModal(true)
                : setToggle(!toggle)
        }
    }

    return (
        <>
            {modal && toggleModal ? <ModalCloseSettingsDelivery title={modalTitle} setToggle={setToggle} show={toggleModal} onHide={() => setToggleModal(false)}/> : null}
            <div className="expand__info">
                <button type="button" disabled={disabled} onClick={onClickToggle} className={cn({'active__expand': toggle})}>
                    {title}<span><img src="img/activearrow.svg" alt="activearrow" /></span>
                </button>
                <div style={{maxHeight: toggle ? scrollHeight : 0}} ref={ref} className={cn('expand__desc', {'active__expand': toggle})}>
                    {children}
                </div>
            </div>
        </>
    )
}
export default Accordion;