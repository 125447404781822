import {
    FORM_APPLY_CODE_SUCCESS, FORM_CLEAR_OLD_GO, FORM_CREATE_GO_DELETE_VAL, FORM_CREATE_GO_SET_VAL,
    FORM_INVALID_FIELDS, FORM_RESET_ERRORS
} from "./Form.constant";
import {clearObject} from "../../hooks/clearObject";

const initialState = {
    form: {
        error: null,
    },
    applyCode: null
}

const Form = (state = initialState, action) => {
    switch (action.type) {
        case FORM_APPLY_CODE_SUCCESS:
            return {
                ...state,
                applyCode: action.payload,
            }
        case FORM_CLEAR_OLD_GO:
            return {
                ...state,
                form: {
                    ...state.form,
                    createGroupOrder: {
                        ...clearObject(state.form.createGroupOrder, action.payload)
                    }
                },
            }
        case FORM_CREATE_GO_SET_VAL:
            return {
                ...state,
                form: {
                    ...state.form,
                    createGroupOrder: {
                        ...state.form.createGroupOrder,
                        [action.payload.uuid]: {
                            ...state?.form?.createGroupOrder?.[action.payload.uuid],
                            ...action.payload.values
                        },
                    }
                },
            }
        case FORM_CREATE_GO_DELETE_VAL:
            return {
                ...state,
                form: {
                    ...state.form.createGroupOrder,
                    [action.payload]: {},
                },
            }
        case FORM_INVALID_FIELDS:
            return {
                ...state,
                form: {
                    ...state.form,
                    error: action.payload
                }
            }
        case FORM_RESET_ERRORS:
            return {
                ...state,
                form: {
                    ...state.form,
                    error: null
                }
            }
        default:
            return state;
    }
}




export default Form;