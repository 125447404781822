
export function useLayoutExtending(BaseLayout, layout, layoutSlots) {

    const currentLayout = layout();
    let usingBaseLayout = true;

    if (layout !== BaseLayout) usingBaseLayout = false;

    layoutSlots.forEach(fn => {
        let undefinedCurrent = currentLayout[fn] === undefined
        if (usingBaseLayout && undefinedCurrent) {
            if (undefinedCurrent) throw new Error(BaseLayout.name + `: missing required slot [${fn}] in layout config`)
        } else {
            if (currentLayout.extend !== undefined) {
                if (undefinedCurrent) currentLayout[fn] = currentLayout.extend()[fn]
            } else {
                if (undefinedCurrent) currentLayout[fn] = BaseLayout()[fn]
            }
        }
    })

    return currentLayout;
}
