import './style.scss'
import React from 'react';
import ProductCard from "../../../modules/ui/ProductCard";
import { MODAL_CHANGE_ORDER, OPEN_MODAL_BUDGET_ALLOCATED } from "../../../store/Modal/Modal.constant";
import { useDispatch } from "react-redux";
import { MAKE_ORDER } from "../../../store/Products/Products.constant";

const MyOrder = ({ joined_member_count, head_count, currency, products, settings, isJoin, slug_uuid, balanceTopUpAmount }) => {
    const totalAmount = (balanceTopUpAmount + settings?.budget) || 0;
    let myProducts = products?.myProducts ? products?.myProducts : [];
    const dispatch = useDispatch();
    let total = 0;
    myProducts.map(item => total += +item.total_price_cents);
    const compilingProductToOrder = (myProducts, auth) => {
        let group_order_items = [];
        myProducts.forEach(item => {
            let quantity = 0;
            let newMappings = {};
            Object.keys(item.menu_items_mapping).forEach(key => {
                quantity += item.menu_items_mapping[key].quantity;
                if (item.menu_items_mapping[key].quantity !== 0) {
                    newMappings[key] = item.menu_items_mapping[key]
                }
            });
            group_order_items.push({
                group_order_member_id: auth.member_id,
                group_order_id: auth.group_order_id,
                menu_id: item.id,
                menu_price: item.price_cents,
                quantity: item.quantity || quantity,
                menu_item_mappings: newMappings,
                sub_total: item.total_price_cents,
                special_instruction: item.special_instruction
            })
        })
        return group_order_items
    }

    let change = false;
    (myProducts?.length !== products?.oldMyOrder.length || JSON.stringify(products?.myProductsId) !== JSON.stringify(products?.oldMyOrderId)) && (change = true)
    !change && myProducts.map(item => {
        products.oldMyOrder.map(oldItem => {
            if (item.id === oldItem.menu_id) {
                if (item.menu_items.length) {
                    JSON.stringify(item.menu_items_mapping) !== JSON.stringify(oldItem.menu_item_mappings) && (change = true)
                } else {
                    item.quantity !== oldItem.quantity && (change = true)
                }
            }
            return null;
        })
        return null;
    })
    return (
        <div className="ordered__cart">
            <div className="container">
                {myProducts?.length ? <div className="outer__ordered--cart">
                    <h2>My Order</h2>
                    <div className="list__ordered">
                        <div className="ordered__container">
                            {myProducts?.map((item, index) => <ProductCard user_id={slug_uuid} modalProductFlag={'CHANGE'} isJoin={isJoin} myProductsId={products?.myProductsId} oldMyOrderId={products.oldMyOrderId} key={index} product={item}
                                className='active__full' />)}
                        </div>
                        <div className="ordered__button">
                            <span>Total</span>
                            <h6>{currency + (+total / 100).toFixed(2)}</h6>
                            {products.oldMyOrderId?.length
                                ? <>
                                    {change ? <button onClick={() => {
                                        dispatch({
                                            type: MODAL_CHANGE_ORDER, payload: {
                                                slug: 'isModalChangeOrder',
                                                user_id: slug_uuid,
                                                info: {
                                                    method: "UPDATE",
                                                    content: {
                                                        id: settings.auth.group_order_id,
                                                        group_order_items: compilingProductToOrder(myProducts, settings.auth),
                                                        path_rerender: slug_uuid,
                                                        user_id: settings.auth.id,
                                                        modalOpen: false,
                                                    }
                                                }
                                            }
                                        })
                                    }} type='button'>Update Order</button> : null}
                                    <button onClick={() => {
                                        dispatch({
                                            type: MODAL_CHANGE_ORDER, payload: {
                                                slug: 'isModalChangeOrder',
                                                user_id: slug_uuid,
                                                info: {
                                                    method: "DELETE",
                                                    content: {
                                                        id: settings.auth.group_order_id,
                                                        group_order_items: products?.oldMyOrder,
                                                        path_rerender: slug_uuid,
                                                        user_id: settings.auth.id,
                                                    }
                                                }
                                            }
                                        });
                                    }} type='button'>Cancel Order</button>
                                </>
                                : <button disabled={joined_member_count >= head_count} onClick={() => {
                                    dispatch({
                                        type: MAKE_ORDER, payload: {
                                            id: settings.auth.group_order_id,
                                            group_order_items: compilingProductToOrder(myProducts, settings.auth),
                                            path_rerender: slug_uuid,
                                            user_id: settings.auth.id,
                                            modalOpen: true,
                                        }
                                    });
                                    // totalAmount < 0 ? dispatch({ type: OPEN_MODAL_BUDGET_ALLOCATED, payload: slug_uuid })
                                    //     :
                                    //     dispatch({
                                    //         type: MAKE_ORDER, payload: {
                                    //             id: settings.auth.group_order_id,
                                    //             group_order_items: compilingProductToOrder(myProducts, settings.auth),
                                    //             path_rerender: slug_uuid,
                                    //             user_id: settings.auth.id,
                                    //             modalOpen: true,
                                    //         }
                                    //     })
                                }} type='button'>Submit Order</button>}
                            {!products.oldMyOrderId?.length && joined_member_count >= head_count ? <div className="ordered__error">The maximum headcount for this order has been reached. Please contact your company's food co-ordinator to inquire</div> : null}
                        </div>
                    </div>
                </div> : null}
            </div>
        </div>
    )
}
export default MyOrder