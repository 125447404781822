import React, {useState} from "react";
import cn from "classnames";
import {useFormikContext} from "formik";
import EscapeOutside from 'react-escape-outside';
import FormInput from "../FormInput";

const FormSelectCheckbox = ({options, classWrap='float__field', title, name}) => {
    const {values, setFieldValue} = useFormikContext();
    const [toggle, setToggle] = useState(false)
    const [newOptions, setNewOptions] = useState([...options])

    const deleteValue = (indexValue) => {
        let newValues = [...values[name]];
        newValues.splice(indexValue, 1);
        setFieldValue(name, newValues);
    }

    const handleAdd = (index) => {
        if (values[name]) {
            const indexValue = values[name]?.indexOf(newOptions[index]);
            if (indexValue > -1) {
                deleteValue(indexValue)
            } else {
                setFieldValue(name, [...values[name], newOptions[index]]);
            }
        } else {
            setFieldValue(name, [newOptions[index]]);
        }
    }

    const addNewObjAndField = () => {
        if(values[name+'Add']){
            const repeat = newOptions.indexOf(values[name+'Add']);
            if(repeat > -1){
                handleAdd(repeat);
            } else {
                setNewOptions([...newOptions, values[name+'Add']]);
                setFieldValue(name, [...values[name] || [], values[name+'Add']]);
            }
            setFieldValue(name+'Add', '');
        }
    }

    const btnUpDown = (keyCode, e) => {
        if(keyCode === 13){
            e.preventDefault()
            addNewObjAndField()
        }
    }

    return (
        <div className={cn(classWrap, 'check__float--dropdown')}>
            <span className={cn({'active': values[name]?.length})}>{title}</span>
            <div className="float__checkbox ">
                <div className="checkbox__container" onClick={(e) => setToggle(true)}>
                    {values[name]?.map((item, index) => <div key={index} className="elem__checkbox">
                        <p>{item}</p>
                        <button type='button' onClick={() => deleteValue(index)}>
                            <img src="/img/checkremove.svg" alt="checkremove" />
                        </button>
                    </div>)}
                </div>
                <EscapeOutside onEscapeOutside={() => setToggle(false)} className={cn('float__checkbox--picker', {'active': toggle})}>
                    <>
                        <button onClick={() => setToggle(false)} type="button" className="cloat__checkbox">
                            <img src="/img/closetag.svg" alt="closetag" />
                        </button>
                        <ul>
                            {newOptions.map((item, index) => <li key={index} className={cn({'active__checkbox': values[name]?.indexOf(item) > -1})}>
                                <button onClick={() => handleAdd(index)} type='button'>{item}</button>
                            </li>)}
                            <li className="add__type">
                                <FormInput options={{
                                    onKeyDown: e => btnUpDown(e.keyCode, e),
                                    onBlur: addNewObjAndField,
                                }} placeholder="Type here..." withoutContainer={true} type='text' name={name + 'Add'} />
                            </li>
                        </ul>
                    </>
                </EscapeOutside>
            </div>
        </div>
    )
}
export default FormSelectCheckbox;