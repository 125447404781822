import React, {useEffect} from "react";
import {compose} from "redux";
import {connect, useDispatch, useSelector} from "react-redux";
import {withFormik} from "formik";
import * as Yup from "yup";

const BaseForm = ({
                      props,
                      values,
                      handleSubmit,
                      validationSchema,
                      children,
                      className,
                      action,
                      method,
                      enableReinitialize,
                      setErrors,
                      setValues,
                      defaultValues = {},
                      get,
                  }) => {

    let formId = action ? action?.split('/').slice(-1).pop().toLowerCase() : null;
    const form = useSelector(state => state.form.form)
    const dispatch = useDispatch()

    const useEfDefVal = () => {
        if (Object.keys(defaultValues).length === 0 && get) {
            dispatch({type: get})
        }
        setValues(defaultValues)
    }


    useEffect(useEfDefVal, [])

    if (action && form && form[action]) setErrors(form[action])

    const useEfValid = () => {
        if (action && form && form[action]) {
            const keys = Object.keys(form[action]);
            const selector = `#${formId} [name="${keys[0]}"]`;
            const errorElement = document.querySelector(selector);
            if (errorElement != null) {
                errorElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});
            }
        }
    }

    useEffect(useEfValid, [form[action]])

    return <form id={formId} className={className} onSubmit={handleSubmit} method={method}>{children}</form>
}

const injectProps = (injectedProps) => WrappedComponent => {
    const InjectProps = props => {
        const newProps = {...injectedProps, ...props};
        return <WrappedComponent {...newProps} />
    }

    return InjectProps
}

const Form = compose(
    injectProps({
        method: 'POST'
    }),
    connect(null, null),
    withFormik({
        validationSchema: props => Yup.object().shape(props.validationSchema),
        mapPropsToValues: () => ({}),
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: (values, {props, setErrors, ...FormikBag}) => {
            setErrors({})
            // props.dispatch({type: FORM_RESET_ERRORS, payload: props.action})
            props.dispatch({type: props.action, payload: {values, FormikBag}})
        },
        displayName: 'Form',
        enableReinitialize: true
    })
)(BaseForm);

export default Form;
