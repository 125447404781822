import cn from "classnames";
import React from "react";

export function TabLayoutBase () {
    return {
        wrapper: children => children,
        header: (children) => {
            return (
                <ul>{children}</ul>
            )
        },
        link: (children, isActive) => {
            return (
                <li className={cn('information-tab__title', {'active': isActive})}>
                    {children}
                </li>
            )
        },
        content: children => children
    }
}

export function TabLayoutMain () {
    return {
        wrapper: (children) => {
            return (
                <div className="order__container">
                    {children}
                </div>
            )
        },
        header: (children) => {
            return (
                <div className='container'>
                    <div className='order__head'>
                        <ul>
                            {children}
                        </ul>
                    </div>
                </div>
            )
        },
        link: (children, isActive) => {
            return (
                <li className={cn({'current__order': isActive})}>
                    {children}
                </li>
            )
        },
    }
}

export function TabLayoutSuccess () {
    return {
        wrapper: (children) => {
            return (
                <div className="success__switcher--container">
                    {children}
                </div>
            )
        },
        header: (children) => {
            return (
                <div className='success__tabs'>
                    <ul>
                        {children}
                    </ul>
                </div>
            )
        },
        link: (children, isActive) => {
            return (
                <li className={cn({'current__tab': isActive})}>
                    <button>{children}</button>
                </li>
            )
        },
        content: children => <div className='elem__box'>{children}</div>
    }
}

export function TabLayoutSubMain () {
    return {
        header: (children) => {
            return (
                <div className='order__switcher'>
                    <ul>
                        {children}
                    </ul>
                </div>
            )
        },
        link: (children, isActive) => {
            return (
                <li className={cn({'active__switcher': isActive})}>
                    {children}
                </li>
            )
        },
    }
}