import Modal from "react-bootstrap/Modal";
import React, {useEffect} from "react";
import Form from "../../Form";
import FormSubmit from "../../Form/FormSubmit";
import FormInputCustom from "../../Form/FormInput/FormInputCustom";
import {FORM_EDIT_PROFILE} from "../../../../store/Form/Form.constant";
import FormImageUpload from "../../Form/FormInput/FormImageUpload";
import {useFormikContext} from "formik";
import * as Yup from 'yup';
import FormSelect from "../../Form/FormSelect";
import FormSelectCheckbox from "../../Form/FormSelectCheckbox";
import dietaryOptions from "../../../../mock/dietaryOptions";

const ModalAuthWrap = ({user_id, additional_delivery_options}) => {
    const {setFieldValue} = useFormikContext();
    const loadGroupOrder = () => {
        setFieldValue('user_id', user_id);
    };
    useEffect(loadGroupOrder, [user_id]);

    return (
        <>
            <FormImageUpload name='profile_image' nameUrl='image_base64' uploadButton='Upload a profile picture' />
            <div className='double__float'>
                <FormInputCustom title='Your first name*' name='first_name' />
                <FormInputCustom title='Your last name*' name='last_name' />
            </div>
            <div className="double__float">
                <FormInputCustom title='Your email*' name='email' />
                <FormInputCustom title='Your mobile phone number*' name='phone' />
            </div>
            {additional_delivery_options?.length
                ? <FormSelect options={additional_delivery_options.map(item => ({value: item, label: item}))} name='selected_delivery_option' title='Select your floor #' />
                : null
            }
            <FormSelectCheckbox name='dietary_types' options={dietaryOptions} title='Dietary Types (Choose or make your own)' />
            <div className="modal__submit">
                <FormSubmit>Update Profile</FormSubmit>
            </div>
        </>
    )
}


const ModalEditProfile = ({onHide, user_id, show, groupOrders, error, auth}) => {
    const schema = {
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        phone: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required')
    };

    let defaultValues = {
        auth: auth,
        image_base64: null,
        user_id: user_id,
        phone: auth?.phone,
        email: auth?.email,
        dietary_types: auth?.dietary_types,
        first_name: auth?.first_name,
        last_name: auth?.last_name,
        selected_delivery_option: auth?.selected_delivery_option,
        profile_image: auth?.profile_image_url ? process.env.REACT_APP_API_URL + auth?.profile_image_url : null,
    }


    return (
        <>
            <Modal show={show} size="lg" centered>
                <Modal.Body>
                    <div className="modal__wrapper">
                        <div className="inner__modal">
                            <div className="team__modal edit-profile__modal">
                                <button onClick={onHide} type='button'>
                                    <img src="/img/closemodal.svg" alt="closemodal" />
                                </button>
                                <div className="modal__info">
                                    {error ? <div className='form__error'>{error}</div> : null}
                                    <div className="modal__info--main">
                                        <h2>Edit Profile</h2>
                                        <p className='mw__100'>You can change your profile information below and click Update Profile to update your information on all future orders. Note: If you update your phone number, you will have to use your updated number to sign-in on all future orders.</p>
                                    </div>
                                    <div className="modal__form">
                                        <Form validationSchema={schema} defaultValues={defaultValues} action={FORM_EDIT_PROFILE} name='modalAuth'>
                                            <ModalAuthWrap additional_delivery_options={groupOrders.additional_delivery_options} user_id={user_id} />
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ModalEditProfile