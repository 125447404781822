import React from "react";
import {useTabState} from "../index";

const TabContent = ({id, children}) => {
    const state = useTabState();

    const displayNone = {
        display: 'none'
    }

    function wrapTabContent(content) {
        let result = [];
        if (content.length !== undefined) {
            content.forEach((element, index) => {
                result[index] = React.cloneElement(element, {
                    style: displayNone,
                    key: index
                })
            })
        } else {
            result = React.cloneElement(content, {
                style: displayNone
            })
        }
        return result
    }

    if (state.current !== id) {
        if (!state.mounted) {
            return null
        } else {
            return wrapTabContent(state.layout.content(children))
        }
    }
    return state.layout.content(children)
}

export default TabContent