import React from 'react';
import './index.scss';

const AnimatedLoader = ({ isLoading }) => {
    if (isLoading) {
        return (
            <div className="loading-animation-container">
                <img src="/img/loading-2.gif" width="375" alt="loading_gif" />
            </div>
        );
    }
    return null;
}

export default AnimatedLoader;
