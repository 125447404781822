import React from "react";

const Loader = ({loading}) => {
    return loading ? <div className="windows8">
        <div className="wBall" id="wBall_1">
            <div className="wInnerBall"/>
        </div>
        <div className="wBall" id="wBall_2">
            <div className="wInnerBall"/>
        </div>
        <div className="wBall" id="wBall_3">
            <div className="wInnerBall"/>
        </div>
        <div className="wBall" id="wBall_4">
            <div className="wInnerBall"/>
        </div>
        <div className="wBall" id="wBall_5">
            <div className="wInnerBall"/>
        </div>
    </div> : null
}
export default Loader;