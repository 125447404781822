import Modal from "react-bootstrap/Modal";
import React from "react";
import {format} from "date-fns";

const ModalOrderSuccess = ({onHide, show, groupOrders}) => {

    return (
        <>
            <Modal show={show} size="lg" centered>
                <Modal.Body>
                    <div className="modal__wrapper">
                        <div className="inner__modal">
                            <div className="success__modal">
                                <button onClick={onHide} type='button'>
                                    <img src="/img/closemodal.svg" alt="closemodal" />
                                </button>
                                <div className="success__image">
                                    <img src="/img/successimage.svg" alt="success" />
                                </div>
                                <div className="sucess__info">
                                    <h2>You order successfully submitted!</h2>
                                    <p>Your order for the event of {groupOrders.delivery_date_time && format(new Date(groupOrders.delivery_date_time * 1000), 'EEEE, MMMM do, yyyy, h:mm aaa')} is submitted and will be added
                                        to the team order.</p>
                                    <div className="btn__wrap">
                                        <button type='button' onClick={onHide}>Done</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ModalOrderSuccess