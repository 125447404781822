import Modal from "react-bootstrap/Modal";
import React, {useEffect} from "react";
import Form from "../../Form";
import FormInputCustom from "../../Form/FormInput/FormInputCustom";
import FormSubmit from "../../Form/FormSubmit";
import {FORM_VERIFICATION_CODE} from "../../../../store/Form/Form.constant";
import {useDispatch} from "react-redux";
import {COMMON_RESEND_VERIFICATION} from "../../../../store/Common/Common.constant";
import {useFormikContext} from "formik";

const ModalConfirmationWrap = ({isResendCode, user_id, error}) => {
    const dispatch = useDispatch();
    const {setFieldValue} = useFormikContext();

    const loadGroupOrder = () => {
        setFieldValue('user_id', user_id);
    };
    useEffect(loadGroupOrder, [user_id]);

    let resendCode = () => {
        dispatch({type: COMMON_RESEND_VERIFICATION, payload: user_id})
    }
    return <>
        <div className="verification__logo">
            <img src="/img/logo.svg" alt="logo" />
        </div>
        <p>Verification code has been sent. Please copy it to the input box below.</p>
        <FormInputCustom title='Verification code' name='verification_code' />
        {error
            ? error === "invalid verification code"
                ? <div className='form__error'>The code you have entered does not match the code that we have sent, please try again or click here to re-send a new code.</div>
                : <div className='form__error'>{error}</div>
            : null
        }
        <div className="btn__wrap">
            <div className="success__close">
                <FormSubmit>Verify code</FormSubmit>
            </div>
            {isResendCode
                ? <div className='btn_resend'>didn't receive it yet? (Sent!)</div>
                : <button className='btn_resend' type='button' onClick={resendCode}>didn't receive it yet?</button>
            }
        </div>
        <div className='verification__info'>By providing your phone number, you consent to receiving a one-time passcode sent by text message to help you sign into TestApp. Standard message and data rates may apply.</div>
    </>
}



const ModalConfirmation = ({order_deadline, show, user_id, ...props}) => {
    return (
        <>
            <Modal show={show} centered>
                <Modal.Body>
                    <div className="modal__wrapper">
                        <div className="inner__modal">
                            <div className="success__modal verification">
                                <Form action={FORM_VERIFICATION_CODE} defaultValues={{user_id: user_id, order_deadline: order_deadline}} className="sucess__info">
                                    <ModalConfirmationWrap {...props} user_id={user_id} />
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ModalConfirmation