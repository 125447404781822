import {call, fork, put, takeLatest} from 'redux-saga/effects'
import {common} from "../../shared/utils/UtilSaga";
import ApiService from "../../shared/services/ApiService";
import {
    CANCEL_GROUP_ORDER,
    COMMON_CHANGE_SETTINGS, COMMON_GET_MY_MEMBER, COMMON_GET_MY_MEMBER_SUCCESS,
    COMMON_RESEND_VERIFICATION, COMMON_RESEND_VERIFICATION_SUCCESS,
    CREATE_PAYMENT_INTENT_GROUP_ORDER, CREATE_PAYMENT_INTENT_GROUP_ORDER_SUCCESS,
    FETCH_GROUP_ORDER, FETCH_GROUP_ORDER_ITEMS, FETCH_GROUP_ORDER_ITEMS_SUCCESS, FETCH_GROUP_ORDER_SUCCESS,
    FETCH_UPLOAD_CSV, PAYMENT_COMPLETE_GROUP_ORDER, PAYMENT_COMPLETE_GROUP_ORDER_SUCCESS, PAYMENT_OLD_CARD_INTENT,
    SET_LOADER_UPLOAD_CSV
} from "./Common.constant";
import {history} from "../../shared/utils/helpers";
import { IS_LOADING_PRODUCT } from '../Products/Products.constant';

function* workerCreatePaymentIntentGO(action) {
    const {redirect, uuid, ...value} = action.payload;
    const data = yield call(ApiService.common.setupIntentGroupOrder, value);
    yield put({type: CREATE_PAYMENT_INTENT_GROUP_ORDER_SUCCESS, payload: {data: data, uuid: uuid}});
    history.push(redirect);
}
function* watchPaymentIntentGroupOrder() {
    yield takeLatest(CREATE_PAYMENT_INTENT_GROUP_ORDER, common(workerCreatePaymentIntentGO));
}

function* workerGetGroupOrderItems(action) {
    const data = yield call(ApiService.common.getGroupOrderItems, action.payload.data);
    yield put({type: FETCH_GROUP_ORDER_ITEMS_SUCCESS, payload: {data: data, user_id: action.payload.user_id}})
}
function* watchGetGroupOrderItems() {
    yield takeLatest(FETCH_GROUP_ORDER_ITEMS, common(workerGetGroupOrderItems));
}

function* workerGetMyMember(action) {
    const data = yield call(ApiService.common.getMyMember);
    yield put({type: COMMON_GET_MY_MEMBER_SUCCESS, payload: {data: data, user_id: action.payload}})
    // yield put({type: FETCH_GROUP_ORDER, payload: {uuid: action.payload}})
}
function* watchGetMyMember() {
    yield takeLatest(COMMON_GET_MY_MEMBER, common(workerGetMyMember));
}

function* workerResendVerification(action) {
    yield call(ApiService.common.resendVerification);
    yield put({type: COMMON_RESEND_VERIFICATION_SUCCESS, payload: action.payload})
}
function* watchResendVerification() {
    yield takeLatest(COMMON_RESEND_VERIFICATION, common(workerResendVerification));
}

function* workerPaymentOldCard(action) {
    const {redirect, uuid, ...val} = action.payload
    const data = yield call(ApiService.common.paymentOldCard, val);
    yield put({type: PAYMENT_COMPLETE_GROUP_ORDER_SUCCESS, payload: {data: data, uuid: uuid}});
    history.push(redirect);
}
function* watchPaymentOldCard() {
    yield takeLatest(PAYMENT_OLD_CARD_INTENT, common(workerPaymentOldCard));
}

function* workerCancelGroupOrder(action) {
    yield call(ApiService.common.cancelGroupOrder, action.payload);
    history.push("/");
}
function* watchCancelGroupOrder() {
    yield takeLatest(CANCEL_GROUP_ORDER, common(workerCancelGroupOrder));
}

function* workerGroupOrder(action) {
    let {uuid, slug_uuid} = action.payload;
    let data = yield call(ApiService.common.fetchGroupOrders, uuid || slug_uuid);
    if(!data) {
        history.push('/error');
    } else {
        yield put({type: FETCH_GROUP_ORDER_SUCCESS, payload: {data: data, user_id: uuid || slug_uuid}});
    }
    yield put({ type: IS_LOADING_PRODUCT, payload: { isLoading: false, user_id: slug_uuid } });
    yield put({type: COMMON_CHANGE_SETTINGS, payload: {data: action.payload, user_id: uuid || slug_uuid}});
}
function* watchGroupOrder() {
    yield takeLatest(FETCH_GROUP_ORDER, common(workerGroupOrder));
}

function* workerPaymentCompleteGroupOrder(action) {
    const {uuid, ...val} = action.payload;
    let data = yield call(ApiService.common.paymentCompleteGroupOrders, val);
    yield put({type: PAYMENT_COMPLETE_GROUP_ORDER_SUCCESS, payload: {data: data, uuid: uuid}});
}
function* watchPaymentCompleteGroupOrder() {
    yield takeLatest(PAYMENT_COMPLETE_GROUP_ORDER, common(workerPaymentCompleteGroupOrder));
}

function* workerUploadCSV(action) {
    const {uuid, ...val} = action.payload;
    yield put({type: SET_LOADER_UPLOAD_CSV, payload: {uuid, data: false}});
    yield call(ApiService.common.uploadCSV, val);
    yield put({type: SET_LOADER_UPLOAD_CSV, payload: {uuid, data: true}});
}
function* watchUploadCSV() {
    yield takeLatest(FETCH_UPLOAD_CSV, common(workerUploadCSV));
}

export default function commonWatchers() {
    return [
        fork(watchGetMyMember),
        fork(watchCancelGroupOrder),
        fork(watchPaymentOldCard),
        fork(watchUploadCSV),
        fork(watchGetGroupOrderItems),
        fork(watchPaymentIntentGroupOrder),
        fork(watchResendVerification),
        fork(watchGroupOrder),
        fork(watchPaymentCompleteGroupOrder),
    ]
}
