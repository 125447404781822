import Modal from "react-bootstrap/Modal";
import React from "react";
import ImagePreview from "../../ImagePreview";
import {format} from "date-fns";
import cn from "classnames";


const ModalOrderEnd = ({onHide, order_deadline, date, title, show, groupOrders}) => {
    const presets = groupOrders?.preset;

    return (
        <>
            <Modal show={show} size="lg" centered>
                <Modal.Body>
                    <div className="modal__wrapper">
                        <div className="inner__modal">
                            <div className="team__modal">
                                <div className="head__modal">
                                    <div className="restaurant__name">
                                        <h6>{presets?.name ? presets.name : 'loading...'}</h6>
                                        <p>{presets?.short_address ? presets.short_address : 'loading...'}</p>
                                    </div>
                                    {!groupOrders ? <div className='placeholder__image form__head' /> : <ImagePreview image={groupOrders?.preset?.banner_image_url} alt="modalimage" />}
                                    <div className="head__logo">
                                        <a className='logo__float' href={groupOrders?.preset?.shared_url ? groupOrders?.preset?.shared_url : '/'}>
                                            {!groupOrders
                                                ? <div className='placeholder__image form__head' />
                                                : <ImagePreview image={groupOrders?.preset?.chefs[0]?.profile_image_url} alt="headlogo" />
                                            }
                                        </a>
                                    </div>
                                </div>
                                <div className="modal__info">
                                    <div className="modal__info--main">
                                        <div className={cn('modal__info__title', {'completed': title === 'COMPLETED', 'cancelled': title === 'CANCELLED'})}>{title}</div>
                                        <span>{date ? (format(new Date(date), 'MMMM dd, EEEE') + ' at ' + format(new Date(date), 'h:mm aaa')) : 'loading...'}</span>
                                    </div>
                                    {title === 'CANCELLED' ? <div className='modal__info--content'>This group order has been cancelled.</div> : null}
                                    {title === 'COMPLETED' ? <div className='modal__info--content'>This group order has been completed and processed on {format(new Date(order_deadline), 'EEEE, MMMM do, yyyy, h:mm aaa')}.</div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ModalOrderEnd