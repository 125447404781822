import {
    CLEAR_PAYMENT_INFO,
    COMMON_CHANGE_BUDGET,
    COMMON_CHANGE_SETTINGS,
    COMMON_CLEAR_CREATE_GROUP_ORDER, COMMON_CLEAR_OLD_GO,
    COMMON_ERROR,
    COMMON_EXCEPTION, COMMON_GET_MY_MEMBER_SUCCESS, COMMON_PAYMENT_COMPLETE, COMMON_RESEND_VERIFICATION_SUCCESS,
    COMMON_TOGGLE_LOADING,
    COMMON_USER_JOIN, CREATE_PAYMENT_INTENT_GROUP_ORDER,
    CREATE_PAYMENT_INTENT_GROUP_ORDER_SUCCESS,
    FETCH_GROUP_ORDER,
    FETCH_GROUP_ORDER_ITEMS_SUCCESS,
    FETCH_GROUP_ORDER_SUCCESS,
    PAYMENT_COMPLETE_GROUP_ORDER_SUCCESS, SET_LOADER_UPLOAD_CSV
} from "./Common.constant";
import {clearObject} from "../../hooks/clearObject";

export const initialState = {
    settings: {},
    createGroupOrder: null,
    loading: false,
    error: null,
    groupOrders: null,
}

const Common = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_COMPLETE_GROUP_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                createGroupOrder: {
                    ...state.createGroupOrder,
                    [action.payload.uuid]: {
                        payment_complete: action.payload.data,
                    }
                },
            }
        case COMMON_PAYMENT_COMPLETE:
            return {
                ...state,
                createGroupOrder: {
                    ...state.createGroupOrder,
                    [action.payload]: {
                        payment_complete: true,
                    }
                },
            }
        case COMMON_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case CLEAR_PAYMENT_INFO:
            return {
                ...state,
                createGroupOrder: {
                    ...state.createGroupOrder,
                    [action.payload]: {
                        payment_complete: null,
                        payment_intent: null
                    }
                },
            }
        case COMMON_CLEAR_OLD_GO:
            return {
                ...state,
                createGroupOrder: {
                    ...clearObject(state.createGroupOrder, action.payload)
                },
                groupOrders: {
                    ...clearObject(state.groupOrders, action.payload)
                },
                settings: {
                    ...clearObject(state.settings, action.payload)
                }
            }
        case SET_LOADER_UPLOAD_CSV:
            return {
                ...state,
                createGroupOrder: {
                    ...state.createGroupOrder,
                    [action.payload.uuid]: {
                        ...state.createGroupOrder[action.payload.uuid],
                        loadCSV: action.payload.data,
                    }
                }
            }
        case COMMON_TOGGLE_LOADING:
            return {
                ...state,
                loading: action.payload ? action.payload : !state.loading,
            }
        case COMMON_USER_JOIN:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.payload]: {
                        ...state.settings[action.payload],
                        isJoin: true,
                    }
                }
            }
        case COMMON_CLEAR_CREATE_GROUP_ORDER:
            return {
                ...state,
                createGroupOrder: {
                    ...state.createGroupOrder,
                    [action.payload]: {}
                },
            }
        case CREATE_PAYMENT_INTENT_GROUP_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                createGroupOrder: {
                    ...state.createGroupOrder,
                    [action.payload.uuid]: {
                        ...state.createGroupOrder?.[action.payload.uuid],
                        payment_intent: action.payload.data,
                    },
                },
            }
        case CREATE_PAYMENT_INTENT_GROUP_ORDER:
            return {
                ...state,
                loading: true,
            }
        case COMMON_RESEND_VERIFICATION_SUCCESS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.payload]: {
                        ...state.settings[action.payload],
                        isResendCode: true,
                    }
                }
            }
        case COMMON_CHANGE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.payload.user_id]: {
                        ...state.settings[action.payload.user_id],
                        ...action.payload.data,
                    }
                }
            }
        case COMMON_CHANGE_BUDGET:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.payload.user_id]: {
                        ...state.settings[action.payload.user_id],
                        budget: action.payload.data
                    }
                }
            }
        case COMMON_GET_MY_MEMBER_SUCCESS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.payload.user_id]: {
                        ...state.settings[action.payload.user_id],
                        auth: {
                            ...state.settings[action.payload.user_id].auth,
                            ...action.payload.data
                        }
                    }
                }
            }
        case FETCH_GROUP_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                groupOrders: {
                    ...state.groupOrders,
                    [action.payload.user_id]: action.payload.data
                },
                settings: {
                    ...state.settings,
                    [action.payload.user_id]: {
                        ...state.settings[action.payload.user_id],
                        budget: action.payload.data.per_person_budget.cents
                    }
                }
            }
        case FETCH_GROUP_ORDER:
            return {
                ...state,
                loading: true,
            }
        case FETCH_GROUP_ORDER_ITEMS_SUCCESS:
            let group_items = action.payload.data.map(item => item.group_order_member_id);
            let onlyUnique = (value, index, self) => self.indexOf(value) === index;
            group_items = group_items.filter(onlyUnique)
            return {
                ...state,
                groupOrders: {
                    ...state.groupOrders,
                    [action.payload.user_id]: {
                        ...state.groupOrders[action.payload.user_id],
                        group_order_items: action.payload.data,
                        joined_member_count: group_items.length,
                    }
                },
            }
        case COMMON_EXCEPTION:
            return state;
        default:
            return state;
    }
}

export default Common
