import Modal from "react-bootstrap/Modal";
import React from "react";
import Form from "../../Form";
import FormCopyInput from "../../Form/FormCopyInput";

const ModalShareLink = ({onHide, show}) => {
    return (
        <>
            <Modal show={show} size="lg" centered>
                <Modal.Body>
                    <div className="modal__wrapper">
                        <div className="inner__modal">
                            <div className="success__modal">
                                <button onClick={onHide} type='button'>
                                    <img src="/img/closemodal.svg" alt="closemodal" />
                                </button>
                                <Form defaultValues={{link: window.location.origin + window.location.pathname}} className="sucess__info">
                                    <h2>Please find your sharable order link below</h2>
                                    <FormCopyInput />
                                    <div className="btn__wrap">
                                        <button type='button' onClick={onHide}>Close</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ModalShareLink