import Modal from "react-bootstrap/Modal";
import React from "react";
import { useDispatch } from "react-redux";
import "../style.scss";
import { DELETE_MY_ORDER, MAKE_ORDER } from "../../../../store/Products/Products.constant";
import { OPEN_MODAL_BUDGET_ALLOCATED } from "../../../../store/Modal/Modal.constant";

const ModalChangeOrder = ({ onHide, show, changeOrderInfo, settings, user_id }) => {
    const dispatch = useDispatch();
    const update = 'Are you sure you want to update your existing order? Your previous order will be removed and updated.';
    const clear = 'Are you sure you want to cancel your order? If you proceed your order will not be submitted. Proceed?';

    const handlerReplaced = (method) => {
        switch (method) {
            case 'UPDATE': {
                settings?.budget < 0 ? dispatch({ type: OPEN_MODAL_BUDGET_ALLOCATED, payload: user_id }) :
                    dispatch({ type: MAKE_ORDER, payload: changeOrderInfo.content })
                return onHide()
            }
            case 'DELETE': {
                dispatch({ type: DELETE_MY_ORDER, payload: changeOrderInfo.content })
                return onHide()
            }
            default: return
        }
    }

    return (
        <>
            <Modal show={show} centered>
                <Modal.Body>
                    <div className="modal__wrapper">
                        <div className="inner__modal">
                            <div className="replace__modal">
                                <button onClick={onHide} type='button'>
                                    <img src="/img/closemodal.svg" alt="closemodal" />
                                </button>
                                <div className="replace__icon">
                                    <img src="/img/modalwarn.svg" alt="modalwarn" />
                                </div>
                                <h6>{changeOrderInfo.method === 'UPDATE' ? update : clear}</h6>
                                <div className="replace__buttons">
                                    <button onClick={() => handlerReplaced(changeOrderInfo.method)} type='button' className="replace__confirm">Yes</button>
                                    <button onClick={onHide} type='button' className="close__replace">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ModalChangeOrder