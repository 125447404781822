import FormInput from "../index";
import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import cn from "classnames";

const FormInputCustom = ({title, classWrap = 'float__input', name, option, ...props}) => {
    const {values} = useFormikContext()
    const [toggle, setToggle] = useState(false)
    const onLoad = () => {
        if (values?.[name]) {
            setToggle(true)
        }
    }
    useEffect(onLoad, [values]);
    const handlerInput = (method) => {
        if (values[name]) {
            return setToggle(true);
        }
        switch (method) {
            case 'FOCUS': return setToggle(true)
            case 'BLUR': return setToggle(false)
            default: return
        }
    }

    return (
        <>
            <div className={classWrap}>
                <span className={cn({'active': toggle})}>{title}</span>
                <FormInput {...props} type='text' name={name} options={{
                    ...option,
                    onFocus: () => handlerInput('FOCUS'),
                    onBlur: () => handlerInput('BLUR'),
                }} withoutContainer={true} />
            </div>
        </>
    )
}
export default FormInputCustom