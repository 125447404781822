import React from 'react';
import {withRouter} from "react-router-dom";
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ViewRouter from './views';

Sentry.init({
  dsn: process.env.REACT_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

function App() {
    return <ViewRouter />;
}

export default withRouter(App);
