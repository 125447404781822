import React from "react";
import FormInput from "../FormInput";

const FormTextarea = ({rows, withoutContainer, classContainer, ...props}) => {
    return <FormInput {...props} withoutContainer={withoutContainer} classContainer={classContainer} inputComponent="textarea" options={{
        rows: rows
    }} />
}

export default FormTextarea

