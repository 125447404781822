import DatePicker from "react-datepicker/es";
import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import 'react-datepicker/src/stylesheets/datepicker.scss';

const FormData = ({disabled, minDate, name}) => {
    const {values, setFieldValue} = useFormikContext();
    const [startDate, setStartDate] = useState(values?.date);
    useEffect(() => {
        if(minDate && values.date && new Date(values.date).valueOf() < minDate){
            setStartDate(new Date(minDate));
            setFieldValue("date", new Date(minDate))
        }
    }, [values.date])
    const handler = (data) => {
        setStartDate(data)
        setFieldValue("date", data)
    }
    return <DatePicker disabled={disabled} minDate={minDate} autoComplete='off' name={name} selected={startDate} onChange={handler} />
}
export default FormData