import React, {useState} from "react";
import {useFormikContext} from "formik";
import FormInput from "../FormInput";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import './style.scss'

const FormCopyInput = ({name = 'link'}) => {
    const [copy, setCopy] = useState(false)
    const {values} = useFormikContext();
    let link = values[name];
    const handlerCopyLink = () => {
        setCopy(true);
        setTimeout(() => setCopy(false), 2000);
    }
    return <div className="congrats__link">
        <FormInput withoutContainer={true} type='text' readonly name={name} />
        <div className="copy__button">
            <CopyToClipboard text={link}>
                <button type='button' onClick={handlerCopyLink}>Copy link</button>
            </CopyToClipboard>
            {copy ? <div className="copy__tool">
                <p>Link copied</p>
            </div> : null}
        </div>
    </div>
}

export default FormCopyInput