import React, {useEffect} from 'react';
import './style.scss'

const Help = () => {
    // const clearStorage = () => {
    //     localStorage.clear();
    //     window.location.reload();
    // }
    useEffect(() => window.location.replace('https://cateredclub.com/'), [])
    return (
        <>
            {/*<div className='help'>*/}
            {/*    <button type='button' onClick={clearStorage}>clear localStorage</button>*/}
            {/*</div>*/}
        </>
    )
}
export default Help
