import React, {useEffect, useState} from 'react';
import Form from "../../../modules/ui/Form";
import FormCopyInput from "../../../modules/ui/Form/FormCopyInput";
import {withRouter, Link} from "react-router-dom";
import {TabLayoutSuccess} from "../../../modules/ui/Tab/TabLayout";
import Tab from "../../../modules/ui/Tab";
import TabLink from "../../../modules/ui/Tab/TabLink";
import TabHeader from "../../../modules/ui/Tab/TabHeader";
import TabContent from "../../../modules/ui/Tab/TabContent";
import UploadFile from "../../../modules/ui/UploadFile";
import {useDispatch} from "react-redux";
import {FETCH_UPLOAD_CSV, SET_LOADER_UPLOAD_CSV} from "../../../store/Common/Common.constant";
import {useFormikContext} from "formik";
import Loader from "../../../modules/ui/Loader";
import cn from "classnames";


const SuccessImportCSV = ({loadCSV, id, error, uuid}) => {
    const dispatch = useDispatch();
    const {values, setFieldValue} = useFormikContext();
    const [loader, setLoader] = useState(false);
    const [errorCSV, setErrorCSV] = useState(false);
    const inviteMembers = () => {
        setLoader(true);
        dispatch({type: FETCH_UPLOAD_CSV, payload: {id, uuid, csv_file: values.csv_file}});
    }
    const updateFile = () => {
        if(error){
            setLoader(false);
            setFieldValue('csv_file', 0);
        }
        if(loadCSV){
            setLoader(false);
        }
    }
    useEffect(updateFile, [error, loadCSV]);
    const handleLoad = () => {
        dispatch({type: SET_LOADER_UPLOAD_CSV, payload: {uuid, data: false}});
    }
    useEffect(handleLoad, []);
    return (
        <>
            <div className="invite__all--head">
                <h2>Invite them all at once</h2>
                <p>By downloading the template, you will be abble to add each team member’s name, phone
                    number, and email and be able to import the CSV share the order link with them in a
                    mass email by clicking Invite Members.</p>
            </div>
            <div className={cn("invite__all--wrapper", {'loader': loader})}>
                <Loader loading={loader} />
                <div className="elem__invite--all">
                    <div className="step__invite">
                        <span />
                        <img src="img/stepspacer.svg" alt="stepspacer" />
                    </div>
                    <div className="info__invite">
                        <h6>Step 1: Download the Group Order CSV file below:</h6>
                        <div className="template__download">
                            <div className="template__info">
                                <img src="img/templateicon.svg" alt="templateicon" />
                                <p>Group order CSV template.csv</p>
                            </div>
                            <div className="template__download--button">
                                <Link to="files/template.csv" target="_blank" download>Download</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elem__invite--all">
                    <div className="step__invite">
                        <span />
                    </div>
                    <div className="info__invite">
                        <h6>Step 2: Import from CSV</h6>
                        <p>Add contacts from a CSV file to the People list via bulk upload</p>
                        <UploadFile setErrorCSV={setErrorCSV} accept='.csv' name='csv_file' />
                        {error || errorCSV
                            ? <div className="upload__error">
                                <p>There was an error with the file you uploaded. Please try again. </p>
                            </div>
                            : null
                        }
                        <div className={cn('invite__members', {'members__invited': loadCSV})}>
                            <button disabled={!values.csv_file} onClick={inviteMembers} type='button'>{loadCSV ? 'Invites Sent!' : 'Invite Members'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Success = ({slug, loadCSV, uuid, pathname, payment_complete, history, id, error}) => {
    const [tabActive, setTabActive] = useState();
    const redirect = () => {
        if (!payment_complete) {
            history.push(pathname + '#team')
        }
    }
    useEffect(redirect, [payment_complete])
    const toGroupOrder = () => {
        window.location.replace(`${window.location.origin}/group_order/${payment_complete?.slug || slug}`);
    }
    return (
        <Form defaultValues={{link: `${window.location.origin}/group_order/${payment_complete?.slug || slug}`}}>
            <div className="congrats__text">
                <span><img src="/img/congrats.svg" alt="congrats" /></span>
                <h2>Congratulations! </h2>
                <p>your order settings are complete. Now you're able
                    to <span>copy and share the below link </span> with your team through Email, or messaging apps,
                    and they'll be able to get some yummy food!</p>
            </div>
            <Tab setActiveTab={setTabActive} current='shareable__link' layout={TabLayoutSuccess}>
                <TabHeader>
                    <TabLink id='shareable__link'>
                        <span>Invite via shareable link</span>
                    </TabLink>
                    <TabLink id='CSV'>
                        <span>Invite via CSV Upload</span>
                    </TabLink>
                </TabHeader>
                <div className="success__box">
                    <TabContent id="shareable__link">
                        <FormCopyInput />
                    </TabContent>
                    <TabContent id="CSV">
                        <SuccessImportCSV loadCSV={loadCSV} error={error} id={id} uuid={uuid} />
                    </TabContent>
                </div>
            </Tab>
            <div className="form__submit">
                <button type='button' disabled={!loadCSV && tabActive === 'CSV'} onClick={toGroupOrder}>Continue To Group Order</button>
            </div>
        </Form>
    )
}
export default withRouter(Success)