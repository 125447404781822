import {call, put, takeLatest} from "redux-saga/effects";
import {ApiFormInvalidFieldException, ApiRequestFailed} from "../services/HttpService";
import {COMMON_EXCEPTION, COMMON_TOGGLE_LOADING} from "../../store/Common/Common.constant";
import {FORM_INVALID_FIELDS, FORM_RESET_ERRORS} from "../../store/Form/Form.constant";


export const fetch = (action, api, payload = null) => {
    function* worker(workerAction) {
        const data = yield call(api, workerAction.payload);
        yield put({type: action + '_SUCCESS', payload: data});
    }
    return function* watcher () {
        yield takeLatest(action, common(worker));
    }
}

export const commonError = function* (err) {
    yield put({
        type: COMMON_EXCEPTION,
        payload: err
    })
}

export const common = (saga, handler = commonError, ...args) => function* (action) {
    yield put({type: FORM_RESET_ERRORS})
    try {
        yield call(saga, ...args, action)
    } catch (err) {
        if (err instanceof ApiFormInvalidFieldException) {
            yield put({type: FORM_INVALID_FIELDS, payload: {form: action.type, message: err.message}});
        } if (err instanceof ApiRequestFailed) {
            yield put({type: COMMON_TOGGLE_LOADING, payload: false});
        } else {
            yield call(handler, ...args, err)
        }
    }
}

export const form = (saga, handler = commonError, ...args) => function* (action) {
    yield put({type: FORM_RESET_ERRORS})
    // action.payload.FormikBag.resetForm({})
    try {
        yield call(saga, ...args, action)
    } catch (err) {
        action.payload.FormikBag.setSubmitting(false)
        if (err instanceof ApiFormInvalidFieldException) {
            yield put({type: FORM_INVALID_FIELDS, payload: {form: action.type, message: err.message}});
        } if (err instanceof ApiRequestFailed) {
            yield put({type: COMMON_TOGGLE_LOADING, payload: false});
        } else {
            yield call(handler, ...args, err)
        }
    }
}


