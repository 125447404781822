import {
    ADD_PRODUCT_MY_ORDER, CLIENT_SECRET_EXCEEDED_AMOUNT, DELETE_MY_ORDER_SUCCESS,
    DELETE_PRODUCT_MY_ORDER, FETCH_OLD_MY_PRODUCTS_SUCCESS, IS_LOADING_PRODUCT, NEW_USER_PRODUCTS,
    ORDER_REPEAT, ORDER_REPEAT_ANSWER, PRODUCTS_CLEAR_OLD_GO, SET_OLD_MY_PRODUCTS
} from "./Products.constant";
import { clearObject } from "../../hooks/clearObject";

export const initialState = {}

const Products = (state = initialState, action) => {
    switch (action.type) {
        case NEW_USER_PRODUCTS:
            return {
                ...state,
                [action.payload]: {
                    myProducts: [],
                    myProductsId: [],
                    oldMyOrder: [],
                    oldMyOrderId: [],
                    orderRepeat: null,
                    clientSecret: null,
                    exceededAmount: null,
                    tax_info: null,
                    isLoading: false,
                }
            }
        case PRODUCTS_CLEAR_OLD_GO:
            return {
                ...clearObject(state, action.payload)
            }
        case ADD_PRODUCT_MY_ORDER:
            let newMyProducts = {
                myProducts: [...state?.[action.payload.user_id]?.myProducts],
                myProductsId: [...state?.[action.payload.user_id]?.myProductsId]
            };
            const indexProduct = state[action.payload.user_id].myProductsId.indexOf(action.payload.product.id);
            if (action.payload.flag === 'CHANGE') {
                newMyProducts.myProducts[indexProduct] = action.payload.product;
            } else if (action.payload.flag === 'ADD') {
                if (indexProduct > -1) {
                    if (action.payload.product.menu_items.length) {
                        newMyProducts.myProducts[indexProduct].menu_items.map(item => {
                            newMyProducts.myProducts[indexProduct].menu_items_mapping[item.id].quantity += action.payload.product.menu_items_mapping[item.id].quantity;
                            newMyProducts.myProducts[indexProduct].menu_items_mapping[item.id].price += action.payload.product.menu_items_mapping[item.id].price;
                            newMyProducts.myProducts[indexProduct].total_price_cents += item.price_cents * +action.payload.product.menu_items_mapping[item.id].quantity;
                            return null;
                        })
                    } else {
                        const quantity = +newMyProducts.myProducts[indexProduct].quantity + +action.payload.product.quantity;
                        newMyProducts.myProducts[indexProduct].quantity = quantity;
                        newMyProducts.myProducts[indexProduct].total_price_cents = +quantity * +action.payload.product.price_cents;
                    }
                } else {
                    newMyProducts.myProducts.push(action.payload.product)
                    newMyProducts.myProductsId.push(action.payload.product.id);
                }
            }
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    ...newMyProducts
                }
            }
        case ORDER_REPEAT_ANSWER:
            if (!action.payload.data) return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    orderRepeat: null
                }
            };
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    myProducts: [...state[action.payload.user_id].orderRepeat],
                    myProductsId: [...state[action.payload.user_id].orderRepeat.map(item => item.id)],
                    orderRepeat: null
                }
            }
        case DELETE_MY_ORDER_SUCCESS:
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    oldMyOrder: [],
                    oldMyOrderId: [],
                    myProducts: [],
                    myProductsId: [],
                }
            }
        case SET_OLD_MY_PRODUCTS:
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    oldMyOrder: action.payload.data,
                    oldMyOrderId: action.payload.data.map(item => item.menu_id),
                }
            }
        case FETCH_OLD_MY_PRODUCTS_SUCCESS:
            let products = {
                myProducts: [],
                myProductsId: [],
            };
            if (!state[action.payload.user_id].myProductsId.length && action.payload.orders.length) {
                action.payload.orders.map(order => {
                    action.payload.menu.map(menuItem => {
                        if (order.menu_id === menuItem.id) {
                            products.myProducts.push({
                                ...menuItem,
                                special_instruction: order.special_instruction,
                                menu_items_mapping: order.menu_item_mappings,
                                total_price_cents: order.sub_total,
                            });
                            products.myProductsId.push(menuItem.id);
                        }
                        return null;
                    })
                    return null;
                })
            }
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    oldMyOrder: action.payload.orders,
                    oldMyOrderId: [...action.payload.orders.map(item => item.menu_id)],
                    ...(products.myProductsId.length ? products : {})
                },
            }
        case ORDER_REPEAT:
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    orderRepeat: [...action.payload.data]
                }
            }
        case CLIENT_SECRET_EXCEEDED_AMOUNT:
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    clientSecret: action.payload.clientSecret,
                    exceededAmount: action.payload.exceededAmount,
                    tax_info: action.payload.tax_info
                }
            }
        case IS_LOADING_PRODUCT:
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    isLoading: action.payload.isLoading
                }
            }
        case DELETE_PRODUCT_MY_ORDER:
            let deleteProductMyProductId = [...state[action.payload.user_id].myProductsId];
            let deleteProductMyProduct = [...state[action.payload.user_id].myProducts];
            deleteProductMyProductId.map((item, index) => {
                if (item === action.payload.data) {
                    deleteProductMyProductId.splice(index, 1)
                    deleteProductMyProduct.splice(index, 1)
                }
                return null
            })
            return {
                ...state,
                [action.payload.user_id]: {
                    ...state[action.payload.user_id],
                    myProducts: deleteProductMyProduct,
                    myProductsId: deleteProductMyProductId
                }
            }
        default:
            return state;
    }
}

export default Products
