export const clearObject = (obj, arrDelete) => {
    let newObject = {...obj}
    arrDelete.forEach((item) => {
        for (let i in newObject){
            if(i === item){
                delete newObject[i]
            }
        }
    })
    return newObject
}