
export const DELETE_PRODUCT_MY_ORDER = '@product/DELETE_PRODUCT_MY_ORDER';
export const ADD_PRODUCT_MY_ORDER = '@product/ADD_PRODUCT_MY_ORDER';
export const ORDER_REPEAT = '@product/ORDER_REPEAT';
export const ORDER_REPEAT_ANSWER = '@product/ORDER_REPEAT_ANSWER';
export const NEW_USER_PRODUCTS = '@product/NEW_USER_PRODUCTS';
export const PRODUCTS_CLEAR_OLD_GO = '@product/PRODUCTS_CLEAR_OLD_GO';

export const MAKE_ORDER = '@product/MAKE_ORDER';
export const IS_LOADING_PRODUCT = '@product/IS_LOADING_PRODUCT';

export const DELETE_MY_ORDER = '@product/DELETE_MY_ORDER';
export const DELETE_MY_ORDER_SUCCESS = '@product/DELETE_MY_ORDER_SUCCESS';

export const SET_OLD_MY_PRODUCTS = '@product/SET_OLD_MY_PRODUCTS';
export const CLIENT_SECRET_EXCEEDED_AMOUNT = '@product/CLIENT_SECRET_EXCEEDED_AMOUNT';
export const FETCH_OLD_MY_PRODUCTS = '@product/FETCH_OLD_MY_PRODUCTS';
export const FETCH_OLD_MY_PRODUCTS_SUCCESS = '@product/FETCH_OLD_MY_PRODUCTS_SUCCESS';

