import Modal from "react-bootstrap/Modal";
import React from "react";
import {useDispatch} from "react-redux";
import {ORDER_REPEAT_ANSWER} from "../../../../store/Products/Products.constant";

const ModalReplaced = ({user_id, onHide, show}) => {
    const dispatch = useDispatch();
    const handlerReplaced = (method) => {
        switch (method) {
            case 'TRUE': {
                dispatch({type: ORDER_REPEAT_ANSWER, payload: {data: true, user_id: user_id}})
                return onHide()
            }
            case 'FALSE': {
                dispatch({type: ORDER_REPEAT_ANSWER, payload: {data: false, user_id: user_id}})
                return onHide()
            }
            default: return
        }
    }

    return (
        <>
            <Modal show={show} size="lg" centered>
                <Modal.Body>
                    <div className="modal__wrapper">
                        <div className="inner__modal">
                            <div className="replace__modal">
                                <div className="replace__icon">
                                    <img src="/img/modalwarn.svg" alt="modalwarn" />
                                </div>
                                <h6>Your cart will be replaced by this selection. Do you want to proceed?</h6>
                                <div className="replace__buttons">
                                    <button onClick={() => handlerReplaced('TRUE')} type='button' className="replace__confirm">Yes</button>
                                    <button onClick={() => handlerReplaced('FALSE')} type='button' className="close__replace">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ModalReplaced