import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import CheckoutForm from "./CheckoutForm";
import {COMMON_TOGGLE_LOADING, PAYMENT_COMPLETE_GROUP_ORDER} from "../../../store/Common/Common.constant";
import {useFormikContext} from "formik";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payment = ({error, uuid, loading, currency, pathname, payment_intent, payment_complete, id, edit}) => {
    const dispatch = useDispatch();
    const {values} = useFormikContext();
    const [stripeErrorMessage, setStripeErrorMessage] = useState('');
    const paymentComplete = () => {
        if (payment_complete) {
            window.location.replace(pathname + '#success');
        }
    }
    useEffect(paymentComplete, [payment_complete])
    let onLoad = () => {
        if (Object.values(values).length && !values.per_person_budget && !values.head_count && !values.name && !(values.confirm?.[0] === 'yas') && !values.delivery_address) {
            return window.location.replace(pathname + '#team');
        }
    }
    useEffect(onLoad, [values])

    const postPaymentKey = async (stripeInfo, result) => {
        let postRes = {};
        try {
            const formData = {
                payment_method_id: result?.setupIntent?.payment_method,
                save_card: values.save_card
            };
            dispatch({type: PAYMENT_COMPLETE_GROUP_ORDER, payload: {id: id, uuid: uuid, data: formData}})
        } catch (e) {
            postRes = {msg: e};
        }
        return postRes;
    };

    const orderHandler = async (stripeInfo, result) => {
        if (result && result.error) {
            dispatch({type: COMMON_TOGGLE_LOADING, payload: false})
            setStripeErrorMessage(result.error.message);
        } else {
            await postPaymentKey(stripeInfo, result);
        }
    };

    const options = {
        clientSecret: payment_intent?.client_secret,
    };

    return (
        <>
            <div className="head__form">
                <h2>Payment Details</h2>
                <p>Complete your checkout details now so you don’t have to <br /> worry about it later.</p>
            </div>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm error={error} pathname={pathname} id={id} uuid={uuid} edit={edit} loading={loading} currency={currency} setStripeErrorMessage={setStripeErrorMessage} stripeErrorMessage={stripeErrorMessage} stripeInfo={payment_intent} orderHandler={orderHandler} />
            </Elements>
            <div className="form__back">
                <NavLink to={pathname + '#delivery'}>Back</NavLink>
            </div>
        </>
    )
}
export default Payment