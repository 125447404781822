import FormInput from "../../../modules/ui/Form/FormInput";
import FormData from "../../../modules/ui/Form/FormData";
import FormTime from "../../../modules/ui/Form/FormTime";
import React, {useEffect, useState} from "react";
import FormCheckbox from "../../../modules/ui/Form/FormCheckbox";
import {useFormikContext} from "formik";
import {useDispatch} from "react-redux";
import {CLEAR_PAYMENT_INFO, COMMON_ERROR, COMMON_PAYMENT_COMPLETE,} from "../../../store/Common/Common.constant";
import {format} from "date-fns";
import {FORM_CREATE_GO_SET_VAL} from "../../../store/Form/Form.constant";
import {history} from "../../../shared/utils/helpers";

const Team = ({address, setAddress, groupOrders, setModalCancel, edit, uuid, minDate, error, pathname}) => {
    const [submitOrdersTime, setSubmitOrdersTime] = useState();
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [priceMinSuccess, setPriceMinSuccess] = useState();
    const {values, setFieldValue} = useFormikContext();
    const dispatch = useDispatch();
    const time = values.time?.split(/[\s:]+/);
    const pre_order_notice_hour = groupOrders?.preset?.pre_order_notice_hour;
    const minCents = groupOrders?.preset?.minimum_order_amount_cents;

    const changeVal = () => {
        if (!values.date && minDate) {
            setFieldValue('date', new Date(minDate));
        }
        if (values && address) {
            address.delivery_address && setFieldValue('delivery_address', address.delivery_address);
            address.company_name && setFieldValue('company_name', address.company_name);
            address.date && setFieldValue('date', address.date);
            address.time && setFieldValue('time', address.time);
            address.delivery_latitude && setFieldValue('delivery_latitude', address.delivery_latitude);
            address.delivery_longitude && setFieldValue('delivery_longitude', address.delivery_longitude);
            setAddress('');
        }
        const allDate = values.date?.setHours(time[2] === 'PM' && +time[0] !== 12 ? +time[0] + 12 : +time[0], time[1], 0);
        if (allDate && pre_order_notice_hour) {
            setSubmitOrdersTime(new Date(allDate - (pre_order_notice_hour * 3600000)))
        }
        setPriceMinSuccess((+values.per_person_budget * +values.head_count * 100) >= +minCents)
        error && priceMinSuccess && dispatch({type: COMMON_ERROR, payload: null});
    }
    useEffect(changeVal, [pre_order_notice_hour, values, minDate, address])

    const onLoadInfo = () => {
        groupOrders?.name && !values?.name?.length && setFieldValue('name', groupOrders.name)
        groupOrders?.head_count && !values?.head_count && setFieldValue('head_count', groupOrders.head_count)
        groupOrders?.per_person_budget && !values?.per_person_budget && setFieldValue('per_person_budget', groupOrders?.per_person_budget?.cents / 100)
        groupOrders?.name && setFieldValue('confirm', ['yas'])
        if (groupOrders?.delivery_date_time) {
            setFieldValue('time', format(new Date(groupOrders?.delivery_date_time * 1000), 'h:mm aaa').toUpperCase())
            setFieldValue('date', new Date(groupOrders?.delivery_date_time * 1000))
        }
    }
    useEffect(onLoadInfo, [groupOrders])

    const onClickContinue = () => {
        if (priceMinSuccess && values.per_person_budget && values.head_count && values.name && values.confirm?.[0] === 'yas') {
            dispatch({type: COMMON_ERROR, payload: null});
            dispatch({type: FORM_CREATE_GO_SET_VAL, payload: {values: values, uuid: uuid}});
            if(edit){
                groupOrders?.head_count !== values?.head_count || groupOrders?.per_person_budget.cents / 100 !== values?.per_person_budget || groupOrders?.payment_status === 'setup_intent_created'
                    ? dispatch({type: CLEAR_PAYMENT_INFO, payload: uuid})
                    : dispatch({type: COMMON_PAYMENT_COMPLETE, payload: uuid})
            }
            history.push(pathname + '#delivery');
        } else {
            setSubmitSuccess(true);
            !priceMinSuccess && dispatch({type: COMMON_ERROR, payload: 'The minimum order amount for this vendor has not been met, please increase your headcount or budget to meet the minimum of ' + groupOrders?.tax_info?.currency + (minCents / 100) + '.'})
        }
    }

    return (
        <>
            <div className="head__form">
                <h2>Create a team order</h2>
                <p>Quickly gather that everyone wants to eats.</p>
            </div>
            <div className="double__field one__length">
                <div className="form__group tooltip__group">
                    <p>Group Order Name*
                        <span>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.88935 1.61083C7.74146 -0.536824 4.25896 -0.537063 2.11083 1.61083C-0.0370629 3.75872 -0.0368238 7.24122 2.11083 9.38935C4.25872 11.5368 7.74122 11.537 9.88935 9.38935C12.037 7.24122 12.0368 3.75896 9.88935 1.61083ZM6.71725 7.89142C6.71725 8.28766 6.39609 8.60882 5.99985 8.60882C5.60361 8.60882 5.28245 8.28766 5.28245 7.89142V5.02182C5.28245 4.62558 5.60361 4.30442 5.99985 4.30442C6.39609 4.30442 6.71725 4.62558 6.71725 5.02182V7.89142ZM5.30708 3.14152C5.29847 3.50285 5.57395 3.79555 5.98718 3.79555C6.41762 3.79555 6.69286 3.50285 6.6931 3.14152C6.68425 2.76297 6.41738 2.47888 5.99555 2.47888C5.57395 2.47888 5.29847 2.76273 5.30708 3.14152Z" fill="#9C9C9C"/>
                            </svg>
                        </span>
                    </p>
                    <div className="tooltip__info">
                        <p>This is the name of your group order that will be displayed when you invite all team members to the order. Make sure that it is descriptive e.g. Team Lunch, Board-meeting etc..</p>
                    </div>
                    <FormInput error={!values.name && submitSuccess} required={true} placeholder='Monday Team Lunch, Tuesday Team Lunch, Friday Board-Meeting…' classContainer='form__field' type="text" name='name' />
                </div>
            </div>
            <div className="double__field">
                <div className="form__group tooltip__group">
                    <p>Headcount*
                        <span>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.88935 1.61083C7.74146 -0.536824 4.25896 -0.537063 2.11083 1.61083C-0.0370629 3.75872 -0.0368238 7.24122 2.11083 9.38935C4.25872 11.5368 7.74122 11.537 9.88935 9.38935C12.037 7.24122 12.0368 3.75896 9.88935 1.61083ZM6.71725 7.89142C6.71725 8.28766 6.39609 8.60882 5.99985 8.60882C5.60361 8.60882 5.28245 8.28766 5.28245 7.89142V5.02182C5.28245 4.62558 5.60361 4.30442 5.99985 4.30442C6.39609 4.30442 6.71725 4.62558 6.71725 5.02182V7.89142ZM5.30708 3.14152C5.29847 3.50285 5.57395 3.79555 5.98718 3.79555C6.41762 3.79555 6.69286 3.50285 6.6931 3.14152C6.68425 2.76297 6.41738 2.47888 5.99555 2.47888C5.57395 2.47888 5.29847 2.76273 5.30708 3.14152Z" fill="#9C9C9C"/>
                            </svg>
                        </span>
                    </p>
                    <div className="tooltip__info">
                        <p>Please enter in the number of people that will be joining the group order. It is always best to choose a higher headcount incase other's end up joining or new members are added to the team etc... For example, if you are planning that there will be 20 people, we recommend putting in 25-30 to be safe.<br />Note: The headcount will be multiplied by the budget to create the initial charge, however, you will only be charged for the people that actually place orders. So if 20 out of 30 people join the order, then the remaining budget for the 10 extra people will be credited back to your Card.</p>
                    </div>
                    <FormInput error={(!values.head_count || !priceMinSuccess) && submitSuccess} numberZeroValue={false} options={{min: edit ? groupOrders?.head_count : 1}} placeholder='50' required={true} classContainer='form__field' type="number" name='head_count' />
                </div>
                <div className="form__group tooltip__group">
                    <p>Per person budget*
                        <span>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.88935 1.61083C7.74146 -0.536824 4.25896 -0.537063 2.11083 1.61083C-0.0370629 3.75872 -0.0368238 7.24122 2.11083 9.38935C4.25872 11.5368 7.74122 11.537 9.88935 9.38935C12.037 7.24122 12.0368 3.75896 9.88935 1.61083ZM6.71725 7.89142C6.71725 8.28766 6.39609 8.60882 5.99985 8.60882C5.60361 8.60882 5.28245 8.28766 5.28245 7.89142V5.02182C5.28245 4.62558 5.60361 4.30442 5.99985 4.30442C6.39609 4.30442 6.71725 4.62558 6.71725 5.02182V7.89142ZM5.30708 3.14152C5.29847 3.50285 5.57395 3.79555 5.98718 3.79555C6.41762 3.79555 6.69286 3.50285 6.6931 3.14152C6.68425 2.76297 6.41738 2.47888 5.99555 2.47888C5.57395 2.47888 5.29847 2.76273 5.30708 3.14152Z" fill="#9C9C9C"/>
                            </svg>
                        </span>
                    </p>
                    <div className="tooltip__info">
                        <p>This is the amount of money that each team member will be able to spend on their order. They will not be able to spend more than this.</p>
                    </div>
                    <FormInput error={(!values.per_person_budget || !priceMinSuccess) && submitSuccess} numberZeroValue={false} options={{min: edit ? groupOrders?.per_person_budget?.cents / 100 : 1}} placeholder='25' required={true} classContainer='form__field' type="number" name='per_person_budget' />
                </div>
            </div>
            <div className="time__field">
                <div className="form__group tooltip__group">
                    <p>Date and time for delivery
                        <span>
                            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.88935 1.61083C7.74146 -0.536824 4.25896 -0.537063 2.11083 1.61083C-0.0370629 3.75872 -0.0368238 7.24122 2.11083 9.38935C4.25872 11.5368 7.74122 11.537 9.88935 9.38935C12.037 7.24122 12.0368 3.75896 9.88935 1.61083ZM6.71725 7.89142C6.71725 8.28766 6.39609 8.60882 5.99985 8.60882C5.60361 8.60882 5.28245 8.28766 5.28245 7.89142V5.02182C5.28245 4.62558 5.60361 4.30442 5.99985 4.30442C6.39609 4.30442 6.71725 4.62558 6.71725 5.02182V7.89142ZM5.30708 3.14152C5.29847 3.50285 5.57395 3.79555 5.98718 3.79555C6.41762 3.79555 6.69286 3.50285 6.6931 3.14152C6.68425 2.76297 6.41738 2.47888 5.99555 2.47888C5.57395 2.47888 5.29847 2.76273 5.30708 3.14152Z" fill="#9C9C9C"/>
                            </svg>
                        </span>
                    </p>
                    <div className="tooltip__info">
                        <p>Our vendors require a certain amount of notice, so you can only select dates that meet their minimum notice requirements. Also be sure to select a delivery time that gives the delivery driver some room for traffic etc... It's best to select a time that has the food arrive earlier than later just in case of unforeseen delays. E.g. if you need the food to arrive at 11:30am, we would advise you to select a 11:15am delivery time.</p>
                    </div>
                    <div className="date__time--double">
                        {minDate && values.date ? <>
                            <FormData disabled={edit} minDate={minDate} name='data' />
                            <FormTime disabled={edit} minDate={minDate} name='time' />
                        </> : null}
                    </div>
                    {error && !priceMinSuccess ? <div className="tip__info">
                        <p>{error}</p>
                    </div> : null}
                </div>
            </div>
            <div className="require__form">
                <div className="elem__require">
                    <div className="require__image">
                        <img src="/img/require1.svg" alt="require" />
                    </div>
                    <div className="require__info">
                        <span>This vendor requires:</span>
                        <p>{pre_order_notice_hour} hours notice</p>
                    </div>
                </div>
                <div className="elem__require">
                    <div className="require__image">
                        <img src="/img/require2.svg" alt="require" />
                    </div>
                    <div className="require__info">
                        <span>All members must submit their orders before:</span>
                        <p>
                            {submitOrdersTime && format(submitOrdersTime, 'EEEE MMMM do') === format(new Date(), 'EEEE MMMM do') ? '(TODAY) ' : null}
                            {submitOrdersTime && format(submitOrdersTime, 'EEEE, MMMM do, h:mm aaa')}
                        </p>
                    </div>
                </div>
                <div className="elem__require">
                    <div className="require__image">
                        <img src="/img/require3.svg" alt="require" />
                    </div>
                    <div className="require__info">
                        <span>Orders will be automatically processed on:</span>
                        <p>
                            {submitOrdersTime && format(submitOrdersTime, 'EEEE MMMM do') === format(new Date(), 'EEEE MMMM do') ? '(TODAY) ' : null}
                            {submitOrdersTime && format(submitOrdersTime, 'EEEE, MMMM do, h:mm aaa')}
                        </p>
                    </div>
                </div>
            </div>
            <div className='form__check'>
                <ul>
                    <li>
                        <FormCheckbox error={values.confirm?.[0] !== 'yas' && submitSuccess} name='confirm' value="yas" title='I understand and agree to the above.' />
                    </li>
                </ul>
            </div>
            <div className="form__submit">
                <button type='button' onClick={onClickContinue}>Continue</button>
            </div>
            {edit ? <div className="form__back">
                <button type='button' onClick={() => setModalCancel(true)}>Cancel Entire order</button>
            </div> : null}
        </>
    )
}
export default Team;