import React, {createContext, useContext, useEffect, useState} from "react";
import { TabLayoutBase } from "./TabLayout";
import {useLayoutExtending} from "../../../hooks/useLayoutExtending";

const TabStateContext = createContext();
const TabDispatchContext = createContext();

let BaseLayout = TabLayoutBase;

const Tab = ({ activeTab, setActiveTab, children, current, layout = BaseLayout, mounted = true }) => {
    const currentLayout = useLayoutExtending(BaseLayout, layout, [
        "wrapper",
        "link",
        "content",
        "header",
    ]);

    const initialState = {
        current: current,
        layout: currentLayout,
        mounted: mounted,
    };

    const [state, setState] = useState(initialState);

    const onLoad = () => {
        setActiveTab && setActiveTab(state.current);
    }
    useEffect(onLoad, [])

    const onChangeActiveTab = () => {
        if (activeTab && activeTab !== state.current) {
            setState((prevState) => ({ ...prevState, current: activeTab }));
        }
    }
    useEffect(onChangeActiveTab, [activeTab])

    const changeTab = (current) => {
        setState((prevState) => ({ ...prevState, current }));
        setActiveTab && setActiveTab(current);
    };

    return (
        <TabStateContext.Provider value={state}>
            <TabDispatchContext.Provider value={changeTab}>
                {currentLayout.wrapper(children)}
            </TabDispatchContext.Provider>
        </TabStateContext.Provider>
    );
};

export function useTabState() {
    const context = useContext(TabStateContext);
    if (context === undefined)
        throw new Error("useTabState нельзя использовать в текущем контексте");
    return context;
}

export function useTabDispatch() {
    const context = useContext(TabDispatchContext);
    if (context === undefined)
        throw new Error(
            "useTabDispatch нельзя использовать в текущем контексте"
        );
    return context;
}

export function useTab() {
    return [useTabState(), useTabDispatch()];
}
export default Tab;
