import React from "react";
import './style.scss'
import FormInput from "../index";
import {useFormikContext} from "formik";


const FormInputAdd = ({name}) => {
    const {values, setFieldValue} = useFormikContext();
    const handlerClickAdd = () => {
        setFieldValue(name, [...values[name], (values[name].length + 1).toString()]);
    }
    const handlerClickDelete = (index) => {
        let arr = [...values[name]];
        arr.splice(index, 1);
        setFieldValue(name, arr);
    }
    return (
        <div className="expand__floor">
            <div className="floor__wrapper">
                {values[name]?.map((item, index) => <div key={index} className="floor__input ">
                    <span>Enter floor number to deliver to</span>
                    <FormInput withoutContainer={true} name={`${name}[${index}]`} type="text" />
                    <button onClick={() => handlerClickDelete(index)} type='button'>
                        <img src="img/removeicon.svg" alt="removeicon" />
                    </button>
                </div>)}
            </div>

            <div className="expand__add">
                <button onClick={handlerClickAdd} type='button'>
                    <span><img src="img/add.svg" alt="add" /></span> Add another floor
                </button>
            </div>
        </div>
    )
}
export default FormInputAdd;