import React from "react";
import FormInputSelect from "../FormInput/FormInputSelect";
import times from "../../../../mock/times";
import {format} from "date-fns";
import {useFormikContext} from "formik";

const FormTime = ({name, minDate, ...props}) => {
    const {values} = useFormikContext();
    const formatterDate = (val, forates) => format(new Date(val), forates).toUpperCase();

    let realTime
    if (formatterDate(minDate, 'mm') - formatterDate(minDate, 'mm')%15 === 45) {
        let house = +formatterDate(minDate, 'h') === 12 ? 1 : +formatterDate(minDate, 'h') + 1;
        realTime = house + ':00' + formatterDate(minDate, ' aaa');
    } else {
        realTime = formatterDate(minDate, 'h:') + (formatterDate(minDate, 'mm') - formatterDate(minDate, 'mm')%15 + 15) + formatterDate(minDate, ' aaa');
    }
    const indexTimes = times.indexOf(realTime);
    return <FormInputSelect {...props} minDate={minDate} select={true} classContainer='time__picker' name={name}
        data={ values?.date &&
            formatterDate(values.date, 'MMMM dd, EEEE') === formatterDate(minDate, 'MMMM dd, EEEE') &&
            indexTimes !== -1 ? times.slice(indexTimes) : times
        } />
}
export default FormTime