import React from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Modal from 'react-bootstrap/Modal';
import "../style.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import StripeCheckoutForm from "../ModalStripeForm/element";
const ModalStripeForm = ({ clientSecret, onHide, exceededAmount, orderHandler }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    return (
        <Modal centered show={clientSecret} onHide={onHide}>
            <Modal.Header closeButton>
                <h3>Complete Order</h3>
            </Modal.Header>
            <Modal.Body className="p-3">
                <Elements clientSecret={clientSecret} stripe={stripePromise}>
                    <StripeCheckoutForm orderHandler={orderHandler} exceededAmount={exceededAmount} onHide={onHide} clientSecret={clientSecret} />
                </Elements>
            </Modal.Body>
        </Modal>
    );
};
export default ModalStripeForm;
