import React from "react";
import {Field} from "formik";
import cn from "classnames";

const FormCheckbox = ({title, error= false, className = 'styled-checkbox', name, value}) => {
    return (
        <>
            <Field id='styled-checkbox-1' name={name} type="checkbox" className={cn(className, {'error': error})} value={value}/>
            <label htmlFor="styled-checkbox-1">{title}</label>
        </>
    )
}

export default FormCheckbox
