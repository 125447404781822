import React, {useState} from 'react';
import './style.scss'
import {NavLink} from "react-router-dom";
import cn from "classnames";
import ContainerDiv from "../../modules/ui/ContainerDiv";
import {useDispatch} from "react-redux";
import {MODAL_TOGGLE} from "../../store/Modal/Modal.constant";

const Header = ({user_id, auth, isJoin}) => {
    const [toggle, setToggle] = useState(false);
    const dispatch = useDispatch();
    const onLoad = () => {
        localStorage.clear();
        window.location.reload();
        setToggle(false);
    }
    const onClickProfile = () => {
        dispatch({type: MODAL_TOGGLE, payload: {data: 'isModalEditProfile', user_id: user_id, boolean: true}})
        setToggle(false)
    }

    return (
        <header className={cn({'header__welcome': isJoin})}>
            <div className="container">
                <div className="outer__header">
                    <ContainerDiv container={isJoin} className="header__logo">
                        <div className="logo__wrap">
                            <NavLink to='/'>
                                <img
                                    src="/img/logo.svg"
                                    alt="logo"
                                    style={{height: '56px'}}
                                />
                            </NavLink>
                        </div>
                        <div className="group__info">
                            <p>GROUP ORDER</p>
                        </div>
                    </ContainerDiv>
                    {isJoin
                        ? <div className={cn('welcome__back--dropdown', {'focused__dropdown': toggle})}>
                            <div onClick={() => setToggle(!toggle)}>
                                <span>
                                    <img src="/img/dropdownarrow.svg" alt="dropdownarrow" />
                                </span>
                                <div className="welcome__profile">
                                    <div className="profile__image">
                                        {auth?.profile_image_url
                                            ? <img src={
                                                process.env.REACT_APP_API_URL + auth?.profile_image_url
                                            } alt='profile'/>
                                            : <span>{auth?.first_name[0]?.toUpperCase() + auth?.last_name[0]?.toUpperCase()}</span>
                                        }
                                    </div>
                                    <div className="profile__info">
                                        <span>Welcome back</span>
                                        <p>{auth?.first_name || 'User'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={cn('dropdown__profile', {'disabled': !toggle})}>
                                <ul>
                                    <li>
                                        <button onClick={onClickProfile} type="button">
                                            <span>
                                                <img src="/img/dropdown1.svg" alt="dropdown" />
                                            </span>
                                            Update Account
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={onLoad} type="button">
                                            <span>
                                                <img src="/img/dropdown2.svg" alt="dropdown" />
                                            </span>
                                            Log Out
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        // ? <div className="welcome__back">
                        //     <p>Welcome back</p>
                        //     <div className="welcome__profile">
                        //         {auth?.profile_image_url
                        //             ? <div className="profile__image">
                        //                 <img onClick={onClickProfile} src={
                        //                     process.env.REACT_APP_API_URL + auth?.profile_image_url
                        //                 } alt='profile'/>
                        //             </div>
                        //             : <div className="profile__image">
                        //                 <span onClick={onClickProfile}>{auth?.first_name[0]?.toUpperCase() + auth?.last_name[0]?.toUpperCase()}</span>
                        //             </div>
                        //         }
                        //         <div className="profile__info">
                        //             <p>{auth?.first_name || 'User'}</p>
                        //             <button onClick={onLoad} type='button'>Not You?</button>
                        //         </div>
                        //     </div>
                        // </div>
                        : null
                    }
                </div>
            </div>
        </header>
    )
}
export default Header
