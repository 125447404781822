import { common } from "../../shared/utils/UtilSaga";
import ApiService from "../../shared/services/ApiService";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
    CLIENT_SECRET_EXCEEDED_AMOUNT,
    DELETE_MY_ORDER, DELETE_MY_ORDER_SUCCESS,
    FETCH_OLD_MY_PRODUCTS, FETCH_OLD_MY_PRODUCTS_SUCCESS,
    IS_LOADING_PRODUCT,
    MAKE_ORDER, SET_OLD_MY_PRODUCTS
} from "./Products.constant";
import { CLOSE_MODAL_STRIPE, MODAL_TOGGLE, OPEN_MODAL_BUDGET_ALLOCATED } from "../Modal/Modal.constant";
import { FETCH_GROUP_ORDER } from "../Common/Common.constant";


function* workerMakeOrder(action) {
    const { path_rerender, user_id, modalOpen, ...props } = action.payload;
    // const socket = yield new WebSocket('wss://cateredclub-rails-staging.herokuapp.com/cable');
    // yield socket.send(JSON.stringify({group_order_member: props.group_order_items}));
    yield put({ type: IS_LOADING_PRODUCT, payload: { isLoading: true, user_id: path_rerender } });
    const data = yield call(ApiService.product.makeOrder, props);
    if (data?.client_secret) {
        yield put({ type: OPEN_MODAL_BUDGET_ALLOCATED, payload: path_rerender })
        yield put({ type: CLIENT_SECRET_EXCEEDED_AMOUNT, payload: { clientSecret: data?.client_secret, exceededAmount: data?.exceeded_amount_with_tax, tax_info: data?.tax_info, user_id: path_rerender } });
        yield put({ type: IS_LOADING_PRODUCT, payload: { isLoading: false, user_id: path_rerender } });
        yield put({ type: CLOSE_MODAL_STRIPE, payload: path_rerender });
        return
    }
    yield put({ type: CLIENT_SECRET_EXCEEDED_AMOUNT, payload: { clientSecret: null, exceededAmount: null, tax_info: null, user_id: path_rerender } });
    yield put({ type: CLOSE_MODAL_STRIPE, payload: path_rerender });
    if (modalOpen) {
        yield put({ type: MODAL_TOGGLE, payload: { data: 'isModalOrderSuccess', user_id: path_rerender } })
    }
    yield put({ type: FETCH_GROUP_ORDER, payload: { slug_uuid: path_rerender } })
    yield put({ type: FETCH_OLD_MY_PRODUCTS, payload: { user_id: path_rerender, order: data, id: user_id } });
    yield put({ type: IS_LOADING_PRODUCT, payload: { isLoading: false, user_id: path_rerender } });
}
function* watchMakeOrder() {
    yield takeLatest(MAKE_ORDER, common(workerMakeOrder));
}

function* workerDeleteMyOrder(action) {
    const { path_rerender, user_id, ...props } = action.payload;
    yield call(ApiService.product.deleteMyOrder, props);
    yield put({ type: DELETE_MY_ORDER_SUCCESS, payload: path_rerender });
    yield put({ type: FETCH_GROUP_ORDER, payload: { slug_uuid: path_rerender } })
    yield put({ type: FETCH_OLD_MY_PRODUCTS, payload: { user_id: path_rerender, order: [], ...user_id } });
}
function* watchDeleteMyOrder() {
    yield takeLatest(DELETE_MY_ORDER, common(workerDeleteMyOrder));
}

function* workerFetchOldMyOrder(action) {
    const { id, menu, user_id, order } = action.payload;
    let data = yield call(ApiService.product.fetchMyProducts, id);
    if (order) {
        yield put({ type: SET_OLD_MY_PRODUCTS, payload: { data: order, user_id: user_id } });
    }
    yield put({ type: FETCH_OLD_MY_PRODUCTS_SUCCESS, payload: { menu: menu, orders: data, user_id: user_id } });
}
function* watchFetchOldMyOrder() {
    yield takeLatest(FETCH_OLD_MY_PRODUCTS, common(workerFetchOldMyOrder));
}

export default function commonWatchers() {
    return [
        fork(watchMakeOrder),
        fork(watchDeleteMyOrder),
        fork(watchFetchOldMyOrder),
    ]
}
