import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";

const UploadImage = ({file, nameUrl, setFieldValue}) => {
    const [state, setState] = useState({
        loading: false,
        thumb: undefined
    });
    const onLoadImage = () => {
        if (file && typeof(file) === 'object') {
            setState({...state, loading: true});
            let reader = new FileReader();
            reader.onloadend = () => {
                setState({loading: false, thumb: reader.result});
                nameUrl && setFieldValue(nameUrl, reader.result);
            };
            reader.readAsDataURL(file);
        }
    }
    useEffect(onLoadImage, [file])
    if (!file) return null;
    if (state.loading) {
        return <p>loading...</p>;
    }
    return <img src={state.thumb || file} alt={file.name} />
}

const FormImageUpload = ({name, nameUrl, accept = ".png, .jpg, .jpeg", uploadButton}) => {
    const {values, setFieldValue} = useFormikContext()
    return (
        <div className="avatar__picker">
            <label htmlFor='file' className="avatar__image">
                {values[name]
                    ? <UploadImage nameUrl={nameUrl} setFieldValue={setFieldValue} file={values[name]} />
                    : <img src="/img/proficon.svg" alt="placeholder" />}
            </label>
            {uploadButton ? <label htmlFor='file' className="upload__button">{uploadButton}</label> : null}
            <input id="file" name={name} type="file" accept={accept} onChange={(event) => {
                setFieldValue(name, event.currentTarget.files[0]);
            }} />
        </div>
    )
}
export default FormImageUpload;