import {all} from 'redux-saga/effects';
/* ADD_WATCHER_IMPORT */
import commonWatchers from './Common/Common.saga'
import formWatchers from "./Form/Form.saga";
import productsWatchers from "./Products/Products.saga";


export default function* rootSaga() {
    const watchers = [
        /* ADD_WATCHER_TO_ROOT_SAGA */
        ...formWatchers,
		...commonWatchers(),
        ...productsWatchers(),
    ];
    yield all(watchers)
}
