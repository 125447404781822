import React, {useState} from "react";
import cn from "classnames";
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";

const StripeElement = ({title, className='float__input', card}) => {
    const [toggle, setToggle] = useState(false);
    const [empty, setEmpty] = useState(true);
    return <div className={className}>
        {card === 'CardNumberElement' ? <CardNumberElement options={{placeholder: ''}} onFocus={() => setToggle(true)} onBlur={() => empty && setToggle(false)} onChange={e => setEmpty(e.empty)} /> : null}
        {card === 'CardExpiryElement' ? <CardExpiryElement options={{placeholder: ''}} onFocus={() => setToggle(true)} onBlur={() => empty && setToggle(false)} onChange={e => setEmpty(e.empty)} /> : null}
        {card === 'CardCvcElement' ? <CardCvcElement options={{placeholder: ''}} onFocus={() => setToggle(true)} onBlur={() => empty && setToggle(false)} onChange={e => setEmpty(e.empty)} /> : null}
        <span className={cn({'active': toggle})}>{title}</span>
    </div>
}
export default StripeElement